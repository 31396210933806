// Manager shape 정의

export interface PagedResponse<T> {
  content: T[];
  currentPage: number;
  startPage: number;
  endPage: number;
  previousPageGroupEnd: number;
  nextPageGroupStart: number;

  hasPrevious: boolean;
  hasNext: boolean;

  totalPages: number;
  size: number;
}

export interface Manager {
  id?: number;
  managerId:string;
  password: string; // 실제 상황에서는 비밀번호를 state에 저장하지 않는 것이 좋음
  company:string;
  crn:string;
  email: string;
  tel:string;
  userCode?:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}

export interface ManagerUpdate {
  id?: number;
  managerId:string;
  password?: string;
  company?:string;
  crn?:string;
  email?: string;
  tel?:string;
  userCode?:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}

export interface SignInPayload {
  // [key: string]: string;
  managerId: string;
  password: string;
  otp?: string;
  isLoggedIn?: boolean;
}
// State Type
export interface ManagerState {
  loading: boolean;
  isLoggedIn: boolean;
  checkRole:null |Manager;
  manager: null | Manager;
  pagedManagers: PagedResponse<Manager> | null;
  managerList: Manager[]
  isIdDuplicated?: boolean;
  isLoading:boolean;
  error: string | null;
  masterKey:string |null;
}
export interface ErrorResponse {
  message: string;
}


// Action Types
export const SIGN_IN = "manager/SIGN_IN";
export const LOGOUT = "LOGOUT";
export const MANAGER_INFO = "MANAGER_INFO";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CHECK_MANAGER_START = "CHECK_MANAGER_START";
export const CHECK_MANAGER_SUCCESS = "CHECK_MANAGER_SUCCESS";
export const CHECK_MANAGER_FAILURE = "CHECK_MANAGER_FAILURE";

export const FETCH_MANAGERLIST_REQUEST = "FETCH_MANAGERLIST_REQUEST";
export const FETCH_MANAGERLIST_SUCCESS = "FETCH_MANAGERLIST_SUCCESS";
export const FETCH_PAGED_MANAGERLIST_SUCCESS = "FETCH_PAGED_MANAGERLIST_SUCCESS";
export const FETCH_MANAGERLIST_FAILURE = "FETCH_MANAGERLIST_FAILURE";

export const DELETE_MANAGER = "DELETE_MANAGER";
export const DELETE_MANAGER_SUCCESS = "DELETE_MANAGER_SUCCESS";
export const DELETE_MANAGER_FAILURE = "DELETE_MANAGER_FAILURE";


export const FETCH_MANAGER_REQUEST = "FETCH_MANAGER_REQUEST";
export const FETCH_MANAGER_SUCCESS = "FETCH_MANAGER_SUCCESS";
export const FETCH_MANAGER_FAILURE = "FETCH_MANAGER_FAILURE";

export const PWINIT_MANAGER = "PWINIT_MANAGER";
export const PWINIT_MANAGER_SUCCESS = "PWINIT_MANAGER_SUCCESS";
export const PWINIT_MANAGER_FAILURE = "PWINIT_MANAGER_FAILURE";

export const FETCH_MASTER_KEY_REQUEST = "FETCH_MASTER_KEY_REQUEST";
export const FETCH_MASTER_KEY_SUCCESS = "FETCH_MASTER_KEY_SUCCESS";
export const FETCH_MASTER_KEY_FAILURE = "FETCH_MASTER_KEY_FAILURE";


export interface SignInAction {
  type: typeof SIGN_IN;
  payload: Manager & { isLoggedIn: boolean }; // User 타입에 isLoggedIn 속성을 추가
}

export interface LogoutAction {
  type: typeof LOGOUT;
}
export interface LoginSucessAction {
  type: typeof LOGIN_SUCCESS;
  payload: boolean;
}
/*
type: 이 프로퍼티는 액션의 타입을 나타내며,
여기서는 typeof SIGN_IN이라는 특정 문자열 값이어야 합니다.
여기서 SIGN_IN은 액션 타입을 구분하는 데 사용되는 상수입니다.
payload: 이 프로퍼티는 액션에 대한 추가 정보를 나타냅니다.
SignInPayload 타입의 객체가 됩니다.
  */
export interface ManagerInfoAction {
  type: typeof MANAGER_INFO;
  payload: Manager;
}
export interface CheckManagerStartAction {
  type: typeof CHECK_MANAGER_START;

}
export interface CheckManagerSuccessAction {
  type: typeof CHECK_MANAGER_SUCCESS;
  payload: Manager;
}
export interface CheckManagerFailureAction {
  type: typeof CHECK_MANAGER_FAILURE;

}


type FetchManagerListRequestAction = {
  type: typeof FETCH_MANAGERLIST_REQUEST;
};

type FetchManagerListSuccessAction = {
  type: typeof FETCH_MANAGERLIST_SUCCESS;
  payload: Manager[];
};
type FetchPagedManagerListSuccessAction = {
  type: typeof FETCH_PAGED_MANAGERLIST_SUCCESS;
  payload: PagedResponse<Manager>;
};

type FetchManagerListFailureAction = {
  type: typeof FETCH_MANAGERLIST_FAILURE;
  payload: string;
};


interface DeleteManagerAction {
  type: typeof DELETE_MANAGER;
}
interface DeleteManagerSuccessAction {
  type: typeof DELETE_MANAGER_SUCCESS;
  payload: string;
}

interface DeleteManagerFailtureAction {
  type: typeof DELETE_MANAGER_FAILURE;
  error: string;
}


interface FetchManagerRequestAction {
  type: typeof FETCH_MANAGER_REQUEST;
}

interface FetchManagerSuccessAction {
  type: typeof FETCH_MANAGER_SUCCESS;
  payload: Manager;
}

interface FetchManagerFailureAction {
  type: typeof FETCH_MANAGER_FAILURE;
  error: string;
}



interface PwInitManagerAction {
  type: typeof PWINIT_MANAGER;
}
interface PwInitManagerSuccessAction {
  type: typeof PWINIT_MANAGER_SUCCESS;
  payload: string;
}

interface PwInitManagerFailtureAction {
  type: typeof PWINIT_MANAGER_FAILURE;
  error: string;
}



interface FetchMasterKeyRequestAction {
  type: typeof FETCH_MASTER_KEY_REQUEST;
}
interface FetchMasterKeySuccessAction {
  type: typeof FETCH_MASTER_KEY_SUCCESS;
  payload: string;
}

interface FetchMasterKeyFailureAction {
  type: typeof FETCH_MASTER_KEY_FAILURE;
  error: string;
}

export type ManagerActionTypes =

  | LoginSucessAction
  | SignInAction
  | LogoutAction
  | ManagerInfoAction
  | CheckManagerStartAction
  | CheckManagerSuccessAction
  | CheckManagerFailureAction
  | FetchManagerListRequestAction
  | FetchManagerListSuccessAction
  | FetchPagedManagerListSuccessAction
  | FetchManagerListFailureAction
  | DeleteManagerAction
  | DeleteManagerSuccessAction
  | DeleteManagerFailtureAction
  | FetchManagerRequestAction
  | FetchManagerSuccessAction
  | FetchManagerFailureAction
  | PwInitManagerAction
  | PwInitManagerSuccessAction
  | PwInitManagerFailtureAction
  |FetchMasterKeyRequestAction
  |FetchMasterKeySuccessAction
  |FetchMasterKeyFailureAction;

