import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
} from "@mui/material";
import { PagedResponse, PaymentBoardList } from "../../types/paymentBoardTypes";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "@/index";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { fetchPagedPaymentBoardList } from "../../redux/paymentBoard/paymentBoardActions";
import PaginationByServer from "../PagenationByServer";
interface PaymentBoardListFormProps {
  pagedArticles: PagedResponse<PaymentBoardList> | null;
}
interface TableCellWithOverflowProps {
  children: React.ReactNode;
  widthProp: string;
  isHeader?: boolean;
}

const TableCellWithOverflow: React.FC<TableCellWithOverflowProps> = ({
  children,
  widthProp,
  isHeader = false, // 기본값은 false
}) => (
  <TableCell
    sx={{
      maxWidth: "650px",
      width: widthProp,
      // textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      // border: "1px solid lightgrey",
      textAlign: "center",
      // padding:"15px"
      fontFamily: isHeader ? "'Nanum Gothic'" : undefined, // isHeader가 true일 때만 글꼴 적용
      fontSize: isHeader ? "16px" : undefined, // isHeader가 true일 때만 글자 크기 적용
      fontWeight: isHeader ? "bold" : undefined, // isHeader가 true일 때만 글자 굵기 적용
    }}
  >
    {children}
  </TableCell>
);
const PaymentBoardListForm: React.FC<PaymentBoardListFormProps> = ({
  pagedArticles,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const [searchText, setSearchText] = useState("");
  console.log("넘겨받은 pagedArticles", pagedArticles);
  console.log("넘겨받은 pagedArticles.content", pagedArticles?.content);

  // 화면 크기에 따라 항목 수 결정
  const determineItemsPerPage = () => {
    const height = window.innerHeight;
    return height < 1200 ? 12 : 24;
  };
  const [itemsPerPage, setItemsPerPage] = useState(determineItemsPerPage());

  useEffect(() => {
    const handleResize = () => {
      console.log("handle Resize 함수 작동");
      const newItemsPerPage = determineItemsPerPage();
      if (newItemsPerPage !== itemsPerPage) {
        setItemsPerPage(newItemsPerPage);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [itemsPerPage]);

  useEffect(() => {
    console.log("paymentBoardListForm-URL설정 useEffect");
    const values = queryString.parse(location.search);
    setSearchText((values.search as string) || "");
    const page = parseInt((values.page as string) || "1", 10);
    console.log("page:", page);
    console.log("size:", itemsPerPage);
    dispatch(
      fetchPagedPaymentBoardList({
        page: page,
        size: itemsPerPage,
        searchText: values.search as string,
      })
    );
  }, [dispatch, location.search, itemsPerPage]);

  const handleSearch = (event: React.KeyboardEvent | React.MouseEvent) => {
    event.preventDefault();
    navigate(
      `/payment-board-list?page=1&search=${encodeURIComponent(searchText)}`
    );
    dispatch(
      fetchPagedPaymentBoardList({
        size: itemsPerPage,
        searchText: searchText,
      })
    );
  };

  const handlePageChange = (pageNumber: number) => {
    const queryString = `?page=${pageNumber}&search=${encodeURIComponent(
      searchText
    )}`;
    navigate(`/payment-board-list${queryString}`);
    dispatch(
      fetchPagedPaymentBoardList({
        page: pageNumber,
        size: itemsPerPage,
        searchText: searchText,
      })
    );
  };

  console.log("paymentBoardListForm렌더링");

  //로우 클릭
  const handleRowClick = (paymentBoardId: string) => {
    navigate(`/payment-board-detail/${paymentBoardId}`); // 상세 페이지로 이동
  };
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        // flexWrap="wrap"
        // justifyContent="space-between"
        // p={0}
        // mx={0}
        // my={0}
        sx={{}}
      >
        <Box>
          <span
            className="h3"
            style={{
              fontWeight: "bold",
              fontFamily: "'Nanum Gothic'",
              color: "black",
              // fontSize: "17px",
            }}
          >
            결제 문의
          </span>
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            label="Search name or company"
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSearch(e)}
            sx={{ minWidth: 200, width: 350 }}
          />
          <Button
            variant="outlined"
            onClick={(e) => handleSearch(e)}
            sx={{ minWidth: "fit-content", height: 55 }}
          >
            <SearchIcon />
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <TableContainer
          component={Paper}
          sx={{
            width: "100%",
            marginBottom: "30px",
            marginTop: "30px",
            // textOverflow: "ellipsis",
            // overflow: "hidden",
            // whiteSpace: "nowrap",
          }}
        >
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead sx={{ backgroundColor: "grey.200" }}>
              <TableRow>
                <TableCellWithOverflow widthProp="6%" isHeader>
                  글번호
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="40%" isHeader>
                  글 내용
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="12%" isHeader>
                  회사명
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="12%" isHeader>
                  부서
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="7%" isHeader>
                  관리자명
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="10%" isHeader>
                  관리자ID
                </TableCellWithOverflow>
                <TableCellWithOverflow widthProp="8%" isHeader>
                  작성일
                </TableCellWithOverflow>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedArticles &&
              pagedArticles.content &&
              pagedArticles.content.length > 0 ? (
                pagedArticles.content.map(
                  (paymentBoard: PaymentBoardList, index) => (
                    <TableRow
                      key={paymentBoard.paymentBoardId}
                      hover // 마우스 오버 스타일
                      onClick={() =>
                        handleRowClick(paymentBoard.paymentBoardId)
                      }
                      style={{ cursor: "pointer" }} // 마우스 포인터 변경
                    >
                      <TableCellWithOverflow widthProp="6%">
                        {paymentBoard.paymentBoardId}
                      </TableCellWithOverflow>
                      <TableCellWithOverflow widthProp="40%">
                        {paymentBoard.inquiryDetail}
                      </TableCellWithOverflow>
                      <TableCellWithOverflow widthProp="12%">
                        {paymentBoard.companyName}
                      </TableCellWithOverflow>
                      <TableCellWithOverflow widthProp="12%">
                        {paymentBoard.department}
                      </TableCellWithOverflow>
                      <TableCellWithOverflow widthProp="7%">
                        {paymentBoard.managerName}
                      </TableCellWithOverflow>
                      <TableCellWithOverflow widthProp="10%">
                        {paymentBoard.managerId}
                      </TableCellWithOverflow>
                      <TableCellWithOverflow widthProp="8%">
                        {paymentBoard.createdTime
                          ? new Date(paymentBoard.createdTime)
                              .toISOString()
                              .split("T")[0]
                          : ""}
                      </TableCellWithOverflow>
                    </TableRow>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        display="flex"
        // justifyContent="flex-end"
        // flexWrap="wrap"
        justifyContent="space-around"
        alignItems="center"
      >
        <Box />
        {pagedArticles ? (
          <PaginationByServer
            pagedUnits={pagedArticles}
            handlePageChange={handlePageChange}
          />
        ) : (
          ""
        )}
        <Button
          variant="contained"
          onClick={() => navigate("/payment-board-list")}
          sx={{ mt: 2, mb: 2 }} // 상단 및 하단 마진 적용
        >
          목록
        </Button>
      </Box>
    </>
  );
};

export default PaymentBoardListForm;
