import React, { useState, FormEvent } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { RootState } from "@/index";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { registerNoticeBoard } from "../../redux/noticeBoard/noticeBoardActions";
import { NoticeBoardRegisterType } from "../../types/noticeBoardTypes";
import { useTheme } from "@mui/material/styles";
const NoticeBoardRegisterForm: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(470));
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [registerForm, setRegisterForm] = useState<NoticeBoardRegisterType>({
    author: "",
    title: "",
    inquiryDetail: "",
  });
  // 입력 필드 값 변경을 처리하는 함수
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;

    if (type === "file") {
        const file = e.target.files ? e.target.files[0] : null;
        setImageFile(file);
    } else {
        setRegisterForm(prev => ({
            ...prev,
            [name]: value
        }));
    }
};


  // 폼 제출을 처리하는 함수
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
      dispatch(registerNoticeBoard(registerForm, imageFile, navigate));
  };
  const handleCancel = () => {
    navigate(-1); // 이전 페이지로 돌아갑니다
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ minWidth: "320", mt:5 }}>
      <Grid container spacing={2}>
      <Grid item xs={isSmallScreen ? 12 : 10}>
          <TextField
            required
            fullWidth
            label="제목"
            name="title"
            value={registerForm.title}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={isSmallScreen ? 12 : 2}>
          <TextField
            required
            fullWidth
            label="작성자"
            name="author"
            value={registerForm.author}
            onChange={handleChange}
          />
        </Grid>

        {/* Repeat for inquiry type */}
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            multiline
            rows={7}
            label="내용"
            name="inquiryDetail"
            value={registerForm.inquiryDetail}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField type="file" fullWidth onChange={handleChange} />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button variant="contained" type="submit" sx={{ mr: 3 }}>
            확인
          </Button>
          <Button variant="outlined" onClick={handleCancel}>
            취소
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default NoticeBoardRegisterForm;
