export interface PagedResponse<T> {
  content: T[];
  currentPage: number;
  startPage: number;
  endPage: number;
  previousPageGroupEnd: number;
  nextPageGroupStart: number;

  hasPrevious: boolean;
  hasNext: boolean;

  totalPages: number;
  size: number;
}

export interface NoticeBoardRegisterType {
  author: string,
  title: string,
  inquiryDetail: string,
}

export interface NoticeBoardList {
  noticeBoardId: string;
  author: string,
  title: string,
  managerId?: string;
  inquiryDetail: string;
  createdTime: string;
}

export interface NoticeBoard {
  noticeBoardId: string;
  author: string,
  title: string,
  managerId?: string;
  inquiryDetail: string;
  fileUrl: string;
  createdTime: string;
}

export interface NoticeBoardState {
  loading: boolean;
  noticeBoard: NoticeBoard | null;
  noticeBoardList: NoticeBoardList[];
  pagedArticles: PagedResponse<NoticeBoardList> | null;
  error: string | null;
}
export const FETCH_NOTICEBOARDLIST_REQUEST =
  "FETCH_NOTICEBOARDLIST_REQUEST";
export const FETCH_NOTICEBOARDLIST_SUCCESS =
  "FETCH_NOTICEBOARDLIST_SUCCESS";
export const FETCH_PAGED_NOTICEBOARDLIST_SUCCESS =
  "FETCH_PAGED_NOTICEBOARDLIST_SUCCESS";
export const FETCH_NOTICEBOARDLIST_FAILURE =
  "FETCH_NOTICEBOARDLIST_FAILURE";
export const FETCH_NOTICEBOARD_REQUEST = "FETCH_NOTICEBOARD_REQUEST";
export const FETCH_NOTICEBOARD_SUCCESS = "FETCH_NOTICEBOARD_SUCCESS";
export const FETCH_NOTICEBOARD_FAILURE = "FETCH_NOTICEBOARD_FAILURE";

type FetchNoticeBoardListRequestAction = {
  type: typeof FETCH_NOTICEBOARDLIST_REQUEST;
};

type FetchNoticeBoardListSuccessAction = {
  type: typeof FETCH_NOTICEBOARDLIST_SUCCESS;
  payload: NoticeBoardList[];
};
type FetchPagedNoticeBoardListSuccessAction = {
  type: typeof FETCH_PAGED_NOTICEBOARDLIST_SUCCESS;
  payload: PagedResponse<NoticeBoardList>
};

type FetchNoticeBoardListFailureAction = {
  type: typeof FETCH_NOTICEBOARDLIST_FAILURE;
  payload: string;
};

interface FetchNoticeBoardRequestAction {
  type: typeof FETCH_NOTICEBOARD_REQUEST;
}

interface FetchNoticeBoardSuccessAction {
  type: typeof FETCH_NOTICEBOARD_SUCCESS;
  payload: NoticeBoard;
}

interface FetchNoticeBoardFailureAction {
  type: typeof FETCH_NOTICEBOARD_FAILURE;
  error: string;
}

export type NoticeBoardActionTypes =
  | FetchNoticeBoardListRequestAction
  | FetchNoticeBoardListSuccessAction
  | FetchPagedNoticeBoardListSuccessAction
  | FetchNoticeBoardListFailureAction
  | FetchNoticeBoardRequestAction
  | FetchNoticeBoardSuccessAction
  | FetchNoticeBoardFailureAction;
