import React from "react";
import {
  TextField,
  Stack,
  // Typography,
  Button,
  Tooltip,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/locale"; // 한국어 로케일 가져오기
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh"; // 초기화 아이콘

interface UserLogsFilterByDateProps {
  startDate?: Date;
  endDate?: Date;
  setStartDate: (date: Date | undefined) => void;
  setEndDate: (date: Date | undefined) => void;
  handleDateChange: (newStartDate: Date, newEndDate: Date) => void;
  handleResetDates: ()=>void;
}

const UserLogsFilterByDate: React.FC<UserLogsFilterByDateProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  handleDateChange,
  handleResetDates
}) => {
  
  const performSearch = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        alert("기간 설정이 잘못 되었습니다.");
      }
      handleDateChange(startDate, endDate);
    }
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {/* <Typography variant="caption" style={{ fontWeight: 'bold', color: '#607d8b', minWidth:'55px' }}>
        조회 기간
      </Typography> */}
      {/* Date range selection */}
      <DatePicker
        selected={startDate}
        onChange={(date: Date | null) => {
          if (date) {
            setStartDate(date);
          }
        }}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        dateFormat="yyyy/MM/dd" // 날짜 형식 설정
        locale={ko} // 한국어 로케일 설정
        customInput={<TextField label="Start Date" />}
      />

      <DatePicker
        selected={endDate}
        onChange={(date: Date | null) => {
          if (date) {
            setEndDate(date);
          }
        }}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        dateFormat="yyyy/MM/dd" // 날짜 형식 설정
        locale={ko} // 한국어 로케일 설정
        customInput={<TextField label="End Date" />}
      />
      {/* 검색 버튼 */}
      <IconButton
        color="primary"
        onClick={performSearch}
        sx={{ p: "5px" }} // 패딩 조정
      >
        <SearchIcon />
      </IconButton>
      {/* 날짜 초기화 버튼 */}
      {/* 초기화 버튼 */}
      <Tooltip title="필터 초기화">
        <IconButton
          color="secondary"
          onClick={handleResetDates}
          sx={{ p: "5px" }}
        >
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default UserLogsFilterByDate;
