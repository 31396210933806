// ProductCard.tsx
import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserLastLoginData } from "../../types/userTypes";

interface UserCardProps {
  user: UserLastLoginData;
  style?: React.CSSProperties;
  date?:string;
}

const UserCardForm: React.FC<UserCardProps> = ({ user, style,date }) => {
  const navigate = useNavigate();

  const handleCardClick = (userId: string) => {
    // dispatch(fetchUser(userId));
    navigate(`/user-detail/${userId}?page=1`);
  };

  return (
    <Card
      sx={{
        minWidth: "180px",
        maxHeight: "140px",
        width: "285px",
        margin: 2,
        borderRadius: 4,
        ...style, // 전달받은 스타일을 Card에 적용
      }}
      key={user.userId}
    >
      <CardActionArea onClick={() => handleCardClick(user.userId)}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="span"
            sx={{ fontSize: 20, ...style }}
          >
            {user.userId}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            component="span"
            sx={{ ...style }}
          >
            {" "}
            {user.companyName}
          </Typography>

          <Box display="flex" flexDirection="column" width="100%">
            <Typography variant="body2" component="span" sx={{ ...style }}>
              User: {user.ein}
            </Typography>
            <Typography variant="body2" component="span" sx={{ ...style }}>
              Last Login: {date}
            </Typography>

            <Typography variant="body2" component="span" sx={{ ...style }}>
              Login Type: {user.loginType}
            </Typography>
            <Typography variant="body2" component="span" sx={{ ...style }}>
              IP address: {user.pcIp}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default UserCardForm;
