import {
  FETCH_TECHNICALBOARDLIST_FAILURE,
  FETCH_TECHNICALBOARDLIST_REQUEST,
  FETCH_TECHNICALBOARDLIST_SUCCESS,
  FETCH_PAGED_TECHNICALBOARDLIST_SUCCESS,
  FETCH_TECHNICALBOARD_FAILURE,
  FETCH_TECHNICALBOARD_REQUEST,
  FETCH_TECHNICALBOARD_SUCCESS,
  TechnicalBoardActionTypes,
  TechnicalBoardState,
} from "../../types/technicalBoardTypes";

const initialState: TechnicalBoardState = {
  loading: false,
  technicalBoard: null,
  technicalBoardList: [],
  pagedArticles: null,
  error: null,
};

// Reducer
export const technicalBoardReducer = (
  state = initialState,
  action: TechnicalBoardActionTypes
): TechnicalBoardState => {
  switch (action.type) {
    case FETCH_TECHNICALBOARDLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TECHNICALBOARDLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        technicalBoardList: action.payload,
        error: "",
      };
    case FETCH_PAGED_TECHNICALBOARDLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pagedArticles: action.payload,
        error: "",
      };
    case FETCH_TECHNICALBOARDLIST_FAILURE:
      return {
        ...state,
        loading: false,
        technicalBoardList: [],
        error: action.payload,
      };

    case FETCH_TECHNICALBOARD_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_TECHNICALBOARD_SUCCESS:
      return { ...state, loading: false, technicalBoard: action.payload };
    case FETCH_TECHNICALBOARD_FAILURE:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
};
