export interface PagedResponse<T> {
  content: T[];
  currentPage: number;
  startPage: number;
  endPage: number;
  previousPageGroupEnd: number;
  nextPageGroupStart: number;

  hasPrevious: boolean;
  hasNext: boolean;

  totalPages: number;
  size: number;
}
export interface PaymentBoardRegisterType {
  companyName: string;
  department: string;
  jobPosition: string;
  userNumber:string;
  managerName: string;
  tel: string;
  email: string;
  inquiryDetail: string;
  agreeToTerms: boolean;
}

export interface PaymentBoardList {
  paymentBoardId: string;
  managerId: string;
  companyName: string;
  department: string;
  managerName: string;
  inquiryDetail: string;
  createdTime: string;
}

export interface PaymentBoard {
  paymentBoardId: string;
  managerId: string;
  companyName: string;
  department: string;  
  jobPosition: string;
  userNumber: string;
  managerName: string;
  tel: string;
  email: string;
  inquiryDetail: string;
  fileUrl: string;
  createdTime: string;
}

export interface PaymentBoardState {
  loading: boolean;
  paymentBoard:  PaymentBoard | null;
  paymentBoardList: PaymentBoardList[];
  pagedArticles: PagedResponse<PaymentBoardList> | null;
  error: string | null;
}
export const FETCH_PAYMENTBOARDLIST_REQUEST =
  "FETCH_PAYMENTBOARDLIST_REQUEST";
export const FETCH_PAYMENTBOARDLIST_SUCCESS =
  "FETCH_PAYMENTBOARDLIST_SUCCESS";
  export const FETCH_PAGED_PAYMENTBOARDLIST_SUCCESS =
  "FETCH_PAGED_PAYMENTBOARDLIST_SUCCESS";
export const FETCH_PAYMENTBOARDLIST_FAILURE =
  "FETCH_PAYMENTBOARDLIST_FAILURE";
export const FETCH_PAYMENTBOARD_REQUEST = "FETCH_PAYMENTBOARD_REQUEST";
export const FETCH_PAYMENTBOARD_SUCCESS = "FETCH_PAYMENTBOARD_SUCCESS";
export const FETCH_PAYMENTBOARD_FAILURE = "FETCH_PAYMENTBOARD_FAILURE";

type FetchPaymentBoardListRequestAction = {
  type: typeof FETCH_PAYMENTBOARDLIST_REQUEST;
};

type FetchPaymentBoardListSuccessAction = {
  type: typeof FETCH_PAYMENTBOARDLIST_SUCCESS;
  payload: PaymentBoardList[];
};
type FetchPagedPaymentBoardListSuccessAction = {
  type: typeof FETCH_PAGED_PAYMENTBOARDLIST_SUCCESS;
  payload: PagedResponse<PaymentBoardList>
};

type FetchPaymentBoardListFailureAction = {
  type: typeof FETCH_PAYMENTBOARDLIST_FAILURE;
  payload: string;
};

interface FetchPaymentBoardRequestAction {
  type: typeof FETCH_PAYMENTBOARD_REQUEST;
}

interface FetchPaymentBoardSuccessAction {
  type: typeof FETCH_PAYMENTBOARD_SUCCESS;
  payload: PaymentBoard;
}

interface FetchPaymentBoardFailureAction {
  type: typeof FETCH_PAYMENTBOARD_FAILURE;
  error: string;
}

export type PaymentBoardActionTypes =
  | FetchPaymentBoardListRequestAction
  | FetchPaymentBoardListSuccessAction
  | FetchPagedPaymentBoardListSuccessAction
  | FetchPaymentBoardListFailureAction
  | FetchPaymentBoardRequestAction
  | FetchPaymentBoardSuccessAction
  | FetchPaymentBoardFailureAction;
