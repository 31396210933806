import NoticeBoardListForm from "../../components/noticeBoard/noticeBoardListForm";
import React from "react";
import { RootState } from "@/index";
import { useSelector } from "react-redux";

const NoticeBoardListPage: React.FC = () => {
  const pagedArticles = useSelector((state: RootState) => state.noticeBoard.pagedArticles);

  console.log(" NoticeBoardListPage 렌더링")
  return (
    <>
      <div className="container mt-3 p-0"  style={{ minWidth: "500px" }}>
        <NoticeBoardListForm pagedArticles={pagedArticles}/>
      </div>
    </>
  );
};

export default NoticeBoardListPage;
