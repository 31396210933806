import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { ManagerUpdatePage } from './managerUpdatePage';
import UpdateMasterKeyPage from './updateMasterKeyPage';

// 각 탭의 내용을 렌더링하는 컴포넌트
function TabPanel(props: { children?: React.ReactNode; index: number; value: number }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MyPage: React.FC = () => {
    const [value, setValue] = useState(0);
  
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
  
    return (
      <Box sx={{ width: '100%', marginTop:5}}>
        <Tabs value={value} onChange={handleChange} aria-label="my page tabs" sx={{marginLeft:5 }}>
          <Tab label="내 정보 수정" />
          <Tab label="마스터키 수정" />
          <Tab label="구독 관리" />
        </Tabs>
        
        <TabPanel value={value} index={0}>
          <ManagerUpdatePage /> {/* 내 정보 수정 탭을 클릭하면 managerUpdatePage 컴포넌트가 렌더링됩니다 */}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UpdateMasterKeyPage/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          Settings Content: Here are your settings.
        </TabPanel>
      </Box>
    );
  };
  
  export default MyPage;