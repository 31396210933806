import React, { useEffect, useState} from "react";
import { useLocation, useNavigate} from "react-router-dom";
import {
  Box,
  FormControl,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Button,
} from "@mui/material";

import { Manager, PagedResponse } from "../../types/managerTypes";
import { grey } from "@mui/material/colors";
import ManagerCardForm from "./managerCardForm";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "@/index";
import { useDispatch } from "react-redux";
import { Company } from "../../types/userTypes";
import { fetchPagedManagerList, getUniqueCompanyNamesOfManager } from "../../redux/manager/managerActions";
import SearchIcon from "@mui/icons-material/Search";
import PaginationByServer from "../PagenationByServer";
import queryString from "query-string";
interface ManagerListFormProps {
  pagedManagers: PagedResponse<Manager> | null;
}
const ManagerListForm: React.FC<ManagerListFormProps> = ({ pagedManagers }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectClick, setSelectClick] = useState(""); //회사선택 스크롤을 통한 접근인가 여부.
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [searchText, setSearchText] = useState("");
  console.log('넘겨받은 pagedManagers',pagedManagers)
  console.log('넘겨받은 pagedManagers.content',pagedManagers?.content)

  // 화면 크기에 따라 항목 수 결정
  const determineItemsPerPage = () => {
    const height = window.innerHeight;
    return height < 1200 ? 12 : 24;
  };
  const [itemsPerPage, setItemsPerPage] = useState(determineItemsPerPage());

  useEffect(() => {
    const handleResize = () => {
      console.log("handle Resize 함수 작동");
      const newItemsPerPage = determineItemsPerPage();
      if (newItemsPerPage !== itemsPerPage) {
        setItemsPerPage(newItemsPerPage);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [itemsPerPage]);

  useEffect(() => {
    console.log("managerListForm-URL설정 useEffect");
    const values = queryString.parse(location.search);
    setSelectedCompany((values.company as string) || "");
    setSearchText((values.search as string) || "");
    const page = parseInt((values.page as string) || "1", 10);
    console.log("page:", page);
    console.log("size:", itemsPerPage);
    dispatch(
      fetchPagedManagerList({
        page: page,
        size: itemsPerPage,
        searchText: values.search as string,
        selectedCompany: values.company as string,
      })
    );
  }, [dispatch, location.search, itemsPerPage]);

  useEffect(() => {
    console.log("managerListForm회사이름들 가져오는 useEffect");
    dispatch(getUniqueCompanyNamesOfManager())
      .then((data) => {
        setCompanyList(data); // 비동기적 처리 가져온 데이터를 상태에 저장
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, [dispatch]);

  useEffect(() => {
    console.log("company, search가 변경될떄 호출되는 use effect - 진입");
    // selectedCompany가 유효한 값일 때만 API를 호출
    if (selectedCompany && selectClick) {
      console.log(
        "company, search가 변경될떄 호출되는 use effect - selectedCompany가 있을때만 호출"
      );
      dispatch(
        fetchPagedManagerList({
          size: itemsPerPage,
          searchText: searchText,
          selectedCompany: selectedCompany,
        })
      );
    }
  }, [selectedCompany, dispatch]);

  // 회사 선택 핸들러
  const handleCompanyChange = (event: SelectChangeEvent) => {
    const newCompany = event.target.value as string;
    setSelectedCompany(newCompany);
    setSelectClick("on");
    console.log("회사명:", event.target.value);
    console.log("기존회사명:", selectedCompany);

    // URL 업데이트와 동시에 API 호출
    const queryString = `?page=1&search=${encodeURIComponent(
      searchText
    )}&company=${encodeURIComponent(newCompany)}`;
    navigate(`/manager-list${queryString}`);

    // 'None'을 선택한 경우 페이지를 1로 리셋하고, company는 빈 문자열로 설정
    if (newCompany === "") {
      navigate(`/manager-list?page=1&search=${encodeURIComponent(searchText)}`);
      dispatch(
        fetchPagedManagerList({
          size: itemsPerPage,
          searchText: searchText,
          selectedCompany: "",
        })
      );
    } else {
      // 다른 회사가 선택되면 해당 회사와 현재 검색어를 사용하여 새로운 데이터 불러오기
      dispatch(
        fetchPagedManagerList({
          size: itemsPerPage,
          searchText: searchText,
          selectedCompany: newCompany,
        })
      );
    }
  };

  const handleSearch = (event: React.KeyboardEvent | React.MouseEvent) => {
    event.preventDefault();
    navigate(
      `/manager-list?page=1&search=${encodeURIComponent(
        searchText
      )}&company=${encodeURIComponent(selectedCompany)}`
    );
    dispatch(
      fetchPagedManagerList({
        size: itemsPerPage,
        searchText: searchText,
        selectedCompany: selectedCompany,
      })
    );
  };

  const handlePageChange = (pageNumber: number) => {
    const queryString = `?page=${pageNumber}&search=${encodeURIComponent(
      searchText
    )}&company=${encodeURIComponent(selectedCompany)}`;
    navigate(`/manager-list${queryString}`);
    dispatch(
      fetchPagedManagerList({
        page: pageNumber,
        size: itemsPerPage,
        searchText: searchText,
        selectedCompany: selectedCompany,
      })
    );
  };

  console.log("managerListForm렌더링");

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        // flexWrap="wrap"
        // justifyContent="space-between"
        p={0}
        mx={0}
        my={0}
        sx={{}}
      >
        {companyList.length > 0 && (
          <FormControl
            variant="outlined"
            sx={{ marginLeft: 0, marginRight: 5, width: 200, minWidth: 100 }}
          >
            <InputLabel id="company-label">Company</InputLabel>
            <Select
              labelId="company-label"
              value={selectedCompany}
              onChange={handleCompanyChange}
              label="Company"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {companyList.map((company) => (
                <MenuItem value={company.companyName} key={company.companyName}>
                  {company.companyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          label="Search user ID or name "
          variant="outlined"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSearch(e)}
          sx={{ minWidth: 100 }}
        />
        <Button
          variant="outlined"
          onClick={(e) => handleSearch(e)}
          sx={{ minWidth: "fit-content" }}
        >
          <SearchIcon />
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        // justifyContent="space-between"
        p={1}
        mx={8}
        my={2}
        bgcolor="background.paper"
        sx={{
          maxWidth: "100%",
          minHeight: "70vh",
          backgroundColor: grey[200],
          borderRadius: 7,
        }}
      >
        {pagedManagers &&
        pagedManagers.content &&
        pagedManagers.content.length > 0 ? (
          pagedManagers.content.map((manager: Manager) => (
            <ManagerCardForm manager={manager} key={manager.managerId} />
          ))
        ) : (
          <div style={{ padding: "20px" }}>매니저가 없습니다.</div>
        )}
      </Box>
      {pagedManagers ? (
        <PaginationByServer
          pagedUnits={pagedManagers}
          handlePageChange={handlePageChange}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ManagerListForm;
