import NoticeBoardDetailPage from "../pages/noticeBoard/noticeBoardListDetailPage";
import NoticeBoardListPage from "../pages/noticeBoard/noticeBoardListPage";
import NoticeBoardRegisterPage from "../pages/noticeBoard/noticeBoardRegisterPage";

const noticeBoardRoutes = [

  {
    path: "/notice-board-register",
    component: NoticeBoardRegisterPage,
    allowedRoles: ["1"],
  },
  {
    path: "/notice-board-list",
    component: NoticeBoardListPage,
  },
  {
    path: "/notice-board-detail/:id",
    component: NoticeBoardDetailPage,
  },



];

export default noticeBoardRoutes;
