import { AnyAction, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
  FETCH_NOTICEBOARDLIST_FAILURE,
  FETCH_NOTICEBOARDLIST_REQUEST,
  FETCH_NOTICEBOARDLIST_SUCCESS,
  FETCH_NOTICEBOARD_FAILURE,
  FETCH_NOTICEBOARD_REQUEST,
  FETCH_NOTICEBOARD_SUCCESS,
  NoticeBoardActionTypes,
  NoticeBoard,
  NoticeBoardList,
  NoticeBoardRegisterType,
  PagedResponse,
  FETCH_PAGED_NOTICEBOARDLIST_SUCCESS,
} from "../../types/noticeBoardTypes";
import { RootState } from "../reducers";
import mainRequest from "../../api/mainRequest";
import { toast } from "react-toastify";
export const registerNoticeBoard =
  (
    registerForm: NoticeBoardRegisterType,
    imageFile: File | null,
    navigate: (path: string) => void
  ) =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    try {
      const formData = new FormData();
      if (imageFile) {
        formData.append("image", imageFile);
      }
      formData.append(
        "registerForm",
        new Blob([JSON.stringify(registerForm)], {
          type: "application/json",
        })
      );
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      const response = await mainRequest.post(
        "/notice-board/onlymanager/register",
        formData,
        config
      );

      if (response.status !== 200) {
        throw new Error("공지사항 게시판 등록 실패");
      }

      const data = response.data;
      console.log(data);
      toast.success("공지사항 게시판에 문의가 등록되었습니다!");
      navigate("/notice-board-list");
    } catch (error) {
      console.error("공지사항 게시판 등록 중 오류:", error);
      toast.error("공지사항 게시판 등록 중 오류 발생!");
    }
  };

export const fetchNoticeBoardList = () => {
  return (dispatch: any) => {
    dispatch(fetchNoticeBoardListRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    mainRequest
      .get("/notice-board/list", config)
      .then((response) => {
        dispatch(fetchNoticeBoardListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchNoticeBoardListFailure(error.message));
      });
  };
};

export const fetchPagedNoticeBoardList = ({
  page = 1,
  size=12,
  searchText = ""}
) => {
  return (dispatch: any) => {
    console.log("fetchPagedNoticeBoardList -action 진입");
    dispatch(fetchNoticeBoardListRequest());
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: {
        page, // Add the page number to the request query parameters
        size,
        ...(searchText && { search: searchText })
      },
    };
    mainRequest
      .get("/notice-board/list-page", config)
      .then((response) => {
        console.log("action-fetchList스프링에서 가져온 reponse.data: ", response.data);
        dispatch(fetchPagedNoticeBoardListSuccess(response.data));
      })
      .catch((error) => {
        console.error("Error fetching notice-board list:", error);
        dispatch(fetchNoticeBoardListFailure(error.message));
      });
  };
};


export const fetchNoticeBoardListRequest = () => {
  return {
    type: FETCH_NOTICEBOARDLIST_REQUEST,
  };
};

export const fetchNoticeBoardListSuccess = (
  noticeBoardList: NoticeBoardList[]
) => {
  return {
    type: FETCH_NOTICEBOARDLIST_SUCCESS,
    payload: noticeBoardList,
  };
};

export const fetchPagedNoticeBoardListSuccess = (
  pagedArticles: PagedResponse<NoticeBoardList>
) => {
  return {
    type: FETCH_PAGED_NOTICEBOARDLIST_SUCCESS,
    payload: pagedArticles,
  };
};

export const fetchNoticeBoardListFailure = (error: string) => {
  return {
    type: FETCH_NOTICEBOARDLIST_FAILURE,
    payload: error,
  };
};

export const fetchNoticeBoard =
  (
    noticeBoardId: string
  ): ThunkAction<
    Promise<NoticeBoard>,
    RootState,
    unknown,
    NoticeBoardActionTypes
  > =>
  async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: FETCH_NOTICEBOARD_REQUEST });

      try {
        const config = {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        const response = await mainRequest.get(
          `/notice-board/detail/${noticeBoardId}`,
          config
        );
        console.log("fetchNoticeBoard의 response.data", response.data);
        dispatch({
          type: FETCH_NOTICEBOARD_SUCCESS,
          payload: response.data,
        });
        resolve(response.data);
        console.log("FETCH_NOTICEBOARD액션- FETCH_NOTICEBOARD성공");
      } catch (error) {
        const err = error as Error;
        dispatch({ type: FETCH_NOTICEBOARD_FAILURE, error: err.message });
        reject(err);
      }
    });
  };
