import {
  DashBoardActionTypes,
  DashBoardState,
  FETCH_COUNTANOMALIESPERDATE_FAILURE,
  FETCH_COUNTANOMALIESPERDATE_REQUEST,
  FETCH_COUNTANOMALIESPERDATE_SUCCESS,
  FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_FAILURE,
  FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_REQUEST,
  FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_SUCCESS,
  FETCH_COUNTLOGINSPERDATE_FAILURE,
  FETCH_COUNTLOGINSPERDATE_REQUEST,
  FETCH_COUNTLOGINSPERDATE_SUCCESS,
  FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_FAILURE,
  FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_REQUEST,
  FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_SUCCESS,
  FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_FAILURE,
  FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_REQUEST,
  FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_SUCCESS,
  FETCH_COUNTWEEKENDLOGINSPERDATE_FAILURE,
  FETCH_COUNTWEEKENDLOGINSPERDATE_REQUEST,
  FETCH_COUNTWEEKENDLOGINSPERDATE_SUCCESS,
} from "../../types/DashBoardTypes";

const initialState: DashBoardState = {
  loading: false,
  LoginsPerDate: [],
  AnomaliesPerDate: [],
  LoginsPerDateOutOfWorkHours: [],
  WeekendLoginsPerDate: [],
  WeekendLoginsDuringWorkHours:[],
  WeekendLoginsOutOfWorkHours:[],
  error: null,
};

export const DashBoardReducer = (
  state = initialState,
  action: DashBoardActionTypes
): DashBoardState => {
  switch (action.type) {
    //1 일별 로그인
    case FETCH_COUNTLOGINSPERDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COUNTLOGINSPERDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        LoginsPerDate: action.payload,
        error: "",
      };
    case FETCH_COUNTLOGINSPERDATE_FAILURE:
      return {
        ...state,
        loading: false,
        LoginsPerDate: [],
        error: action.payload,
      };
      // 2 일별 이상징후
      case FETCH_COUNTANOMALIESPERDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COUNTANOMALIESPERDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        AnomaliesPerDate: action.payload,
        error: "",
      };
    case FETCH_COUNTANOMALIESPERDATE_FAILURE:
      return {
        ...state,
        loading: false,
        AnomaliesPerDate: [],
        error: action.payload,
      };

//3 일별 근무시간외 로그인
    case FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        LoginsPerDateOutOfWorkHours: action.payload,
        error: "",
      };
    case FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_FAILURE:
      return {
        ...state,
        loading: false,
        LoginsPerDateOutOfWorkHours: [],
        error: action.payload,
      };
    //4 주말로그인 현황

      case FETCH_COUNTWEEKENDLOGINSPERDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COUNTWEEKENDLOGINSPERDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        WeekendLoginsPerDate: action.payload,
        error: "",
      };
    case FETCH_COUNTWEEKENDLOGINSPERDATE_FAILURE:
      return {
        ...state,
        loading: false,
        WeekendLoginsPerDate: [],
        error: action.payload,
      };

      //5. 주말 근무시간 내 접속자수 
      case FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        WeekendLoginsDuringWorkHours: action.payload,
        error: "",
      };
    case FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_FAILURE:
      return {
        ...state,
        loading: false,
        WeekendLoginsDuringWorkHours: [],
        error: action.payload,
      };

      //6 주말 근무시간 외 접속자수 
      case FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        WeekendLoginsOutOfWorkHours: action.payload,
        error: "",
      };
    case FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_FAILURE:
      return {
        ...state,
        loading: false,
        WeekendLoginsOutOfWorkHours: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default DashBoardReducer;
