import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { checkUserIdDuplication } from "../../redux/user/userActions";

import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "@/redux/reducers";
import { useNavigate } from "react-router-dom";
import { userRegister } from "../../redux/user/userActions";

//하단 장식
const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.kind-soft.com/">
        KINDSOFT Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();

export const UserRegisterPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();

  //id 관련
  const [userId, setUserId] = useState("");
  const [idMessage, setIdMessage] = useState<string | null>(null);
  const [idColor, setIdColor] = useState<string>("red");
  const [idDisabled, setIdDisabled] = useState(false);
  const [isIdDuplicated, setIsIdDuplicated] = useState(false);

  //매니저id 관련
  // const [managerId, setManagerId] = useState("");
  // const [managerIdValid, setManagerIdValid] = useState(false);
  // const [managerIdError, setManagerIdError] = useState<string | null>(null);

  //사원 이름/번호 관련

  const [ein, setEin] = useState("");
  const [einValid, setEinValid] = useState(false);
  const [einError, setEinError] = useState<string | null>(null);

  //부서 관련

  const [department, setDepartment] = useState("");
  const [departmentValid, setDepartmentValid] = useState(false);
  const [departmentError, setDepartmentError] = useState<string | null>(null);

  //이메일 관련

  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);

  //전화번호 관련
  const [tel, setTel] = useState("");
  const [telValid, setTelValid] = useState(false);
  const [telError, setTelError] = useState<string | null>(null);

  //id관련 함수

  const handleUserIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // 한글 및 특수문자를 제외한 영문과 숫자만 허용
    const regex = /^[A-Za-z0-9]+$/;
    if (regex.test(value) || value === "") {
      setUserId(value);
    } else {
      setIdMessage("영문과 숫자만 사용할 수 있습니다.");
      setIdColor("red");
    }
  };

  const handleVerifyId = async () => {
    console.log("id", userId);
    const result = await dispatch(checkUserIdDuplication(userId));
    console.log("result", result);
    if (result && !result.duplicated) {
      //id가 중복되지 않은 경우
      setIsIdDuplicated(false);
      setIdMessage("사용 가능합니다.");
      setIdColor("green");
      setIdDisabled(true);
    } else {
      setIsIdDuplicated(true);
      setIdMessage("ID가 중복 되었습니다.");
      setIdColor("red");
    }
  };

  //매니저iD  관련 함수
  // const handleManagerIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   setManagerId(value);
  //   setManagerIdValid(value.trim() !== ""); //  비어있지 않은 경우에만 true
  // };

  //사원 번호/이름 관련 함수
  const handleEinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEin(value);
    setEinValid(value.trim() !== ""); //  비어있지 않은 경우에만 true
  };

  //컴퍼니 관련 함수
  const handleDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDepartment(value);
    setDepartmentValid(value.trim() !== ""); // 컴퍼니명이 비어있지 않은 경우에만 true
  };

  //e메일 관련 함수
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    checkEmailValidity(e.target.value);
  };
  const checkEmailValidity = (email: string) => {
    if (emailRegex.test(email)) {
      setEmailValid(true);
      setEmailError(null);
    } else {
      setEmailValid(false);
      setEmailError("이메일 형식에 맞지 않습니다.");
    }
  };

  //전화번호 관련 함수
  const telRegex = /^\d*-\d*-\d*$/; // 최소한 두 개의 '-' 가 포함되어야 함
  const handleTelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTel(value);

    if (telRegex.test(value)) {
      setTelValid(true);
      setTelError(null);
    } else {
      setTelValid(false);
      setTelError("대시('-')를 포함한 휴대폰 번호를 입력해주세요");
    }
  };

  //submit 함수
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(
      data.get("userId"),
      data.get("ein"),
      data.get("department"),
      data.get("email"),
      data.get("tel")
    );
    const userData = {
      userId: data.get("userId") as string,
      // managerId: data.get("managerId") as string,
      ein: data.get("ein") as string,
      department: data.get("department") as string,
      email: data.get("email") as string,
      tel: data.get("tel") as string,
    };
    console.log("signup-handleSubmit메서드 작동");
    dispatch(userRegister(userData, navigate));
  };

  const handleBack = () => {
    navigate(-1); // 뒤로가기
  };

  return (
    // <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs" sx={{ minWidth: 320 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            marginBottom:"40px"
          }}
        >
          <Typography component="h1" variant="h5">
            사원 등록
          </Typography>
          <Box
            component="img"
            sx={{
              position: "absolute",
              left: "100%",
              marginLeft: "40px",
              // width: 10, // 너비 설정
              // height: 65, // 높이 설정
              // objectFit: "cover", // 이미지가 박스에 맞게 조정
            }}
            alt={""}
            // src="/images/JJokomi-removebg1.png"
            // src="/images/ci2-removebg-preview.png"
            src="/images/kiny1-removebg-preview.png"
            // src="/images/kiny1-removebg-preview.png"
          />
        </Box>
        <Box
          component="form"
          // noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 8 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                required
                fullWidth
                id="userId"
                label="ID"
                name="userId"
                autoComplete="id"
                autoFocus
                value={userId}
                onChange={handleUserIdChange}
                error={isIdDuplicated}
                helperText={idMessage} // 중복 체크 메시지 출력
                FormHelperTextProps={{ style: { color: idColor } }}
                inputProps={{ readOnly: idDisabled }} //  필드 활성화/비활성화
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ height: 50 }}
                onClick={handleVerifyId} // 중복 체크 함수 호출
              >
                ID 중복 확인
              </Button>
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="managerId" // employee identification number
                label="담당 관리자 ID"
                name="managerId"
                onChange={handleManagerIdChange}
                error={!!managerIdError} // 에러가 있을 때 필드에 에러 표시
                helperText={managerIdError} // 에러 메시지 출력
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="ein" // employee identification number
                label="사원 이름 또는 번호"
                name="ein"
                onChange={handleEinChange}
                error={!!einError} // 에러가 있을 때 필드에 에러 표시
                helperText={einError} // 에러 메시지 출력
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="department"
                label="부서명"
                name="department"
                onChange={handleDepartmentChange}
                error={!!departmentError} // 에러가 있을 때 필드에 에러 표시
                helperText={departmentError} // 에러 메시지 출력
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                onChange={handleEmailChange}
                error={!!emailError} // 이메일 에러가 있을 때, 필드에 에러 표시
                helperText={emailError} // 에러 메시지 출력
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="tel"
                label="휴대폰 번호"
                name="tel"
                autoComplete="tel"
                onChange={handleTelChange}
                error={!!telError} // 에러가 있을 때 필드에 에러 표시
                helperText={telError} // 에러 메시지 출력
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              !(
                (
                  !isIdDuplicated &&
                  einValid &&
                  emailValid &&
                  telValid &&
                  departmentValid
                )
                // && managerIdValid
              )
            }
          >
            사원 등록
          </Button>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                sx={{ height: 30, mt: 5 }}
                onClick={handleBack}
              >
                뒤로 가기
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
    // </ThemeProvider>
  );
};
