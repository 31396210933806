import {
  FETCH_NOTICEBOARDLIST_FAILURE,
  FETCH_NOTICEBOARDLIST_REQUEST,
  FETCH_NOTICEBOARDLIST_SUCCESS,
  FETCH_PAGED_NOTICEBOARDLIST_SUCCESS,
  FETCH_NOTICEBOARD_FAILURE,
  FETCH_NOTICEBOARD_REQUEST,
  FETCH_NOTICEBOARD_SUCCESS,
  NoticeBoardActionTypes,
  NoticeBoardState,
} from "../../types/noticeBoardTypes";

const initialState: NoticeBoardState = {
  loading: false,
  noticeBoard: null,
  noticeBoardList: [],
  pagedArticles: null,
  error: null,
};

// Reducer
export const noticeBoardReducer = (
  state = initialState,
  action: NoticeBoardActionTypes
): NoticeBoardState => {
  switch (action.type) {
    case FETCH_NOTICEBOARDLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTICEBOARDLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        noticeBoardList: action.payload,
        error: "",
      };
    case FETCH_PAGED_NOTICEBOARDLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pagedArticles: action.payload,
        error: "",
      };
    case FETCH_NOTICEBOARDLIST_FAILURE:
      return {
        ...state,
        loading: false,
        noticeBoardList: [],
        error: action.payload,
      };

    case FETCH_NOTICEBOARD_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_NOTICEBOARD_SUCCESS:
      return { ...state, loading: false, noticeBoard: action.payload };
    case FETCH_NOTICEBOARD_FAILURE:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
};
