import React from "react";
import { RootState } from "@/index";
import { useSelector } from "react-redux";
import ManagerListForm from "../../components/admin/managerListForm";

const ManagerListPage: React.FC = () => {
  const pagedManagers = useSelector(
    (state: RootState) => state.manager.pagedManagers
  );
  // const theme = useTheme(); // 테마 훅 사용
  console.log("managerListPage렌더링 -pagedManagers:", pagedManagers);
  return (
    <>
      <div className="container mt-5" style={{ minWidth: "400px" }}>
        <ManagerListForm pagedManagers={pagedManagers}></ManagerListForm>
      </div>
    </>
  );
};

export default ManagerListPage;
