import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import {  RootState } from "@/index";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import NoticeBoardDetailForm from "../../components/noticeBoard/noticeBoardListDetailForm";
import { fetchNoticeBoard } from "../../redux/noticeBoard/noticeBoardActions";

type RouteParams = {
  [key: string]: string | undefined;
};

const NoticeBoardDetailPage: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { id } = useParams<RouteParams>();
  const noticeBoardId = String(id);

  const { noticeBoard, loading, error } = useSelector(
    (state: RootState) => state.noticeBoard
  );
  useEffect(() => {
    console.log("noticeBoardId:", noticeBoardId);
    if (noticeBoardId && noticeBoardId !== "") {
      dispatch(fetchNoticeBoard(noticeBoardId));
    }
  }, [dispatch, noticeBoardId]);

  console.log("NoticeBoardDetailpage 렌더링");

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;
  if (!noticeBoard) return <div>No user Found</div>;

  return (
    <div className="container mt-5">
      <NoticeBoardDetailForm
        noticeBoard={noticeBoard}
      ></NoticeBoardDetailForm>
    </div>
  );
};

export default NoticeBoardDetailPage;
