import { UserUpdatePage } from "../pages/user/userUpdatePage";
import UserDetailpageTemp from "../pages/user_temp/userDetailPage_TEMP";
import UserListPageTemp from "../pages/user_temp/userListPage_TEMP";
import {UserRegisterPage } from "../pages/user/userRegisterPage";
import UserListPage from "../pages/user/userListPage";
import UserDetailpage from "../pages/user/userDetailPage";
import UserLogsPage from "../pages/user/userLogsPage";

const userRoutes = [
  {
    path: "/user-register",
    component: UserRegisterPage,
    allowedRoles: ["0", "1"],
  },
  {
    path: "/user-list",
    component: UserListPage,
    allowedRoles: ["0", "1"],
  },
  {
    path: "/user-detail/:id",
    component: UserDetailpage,
    allowedRoles: ["0", "1"],
  },
  {
    path: "/user-update/:id",
    component:  UserUpdatePage,
    allowedRoles: ["0","1"],
  },
  {
    path: "/user-list-temp/:page",
    component: UserListPageTemp,
  },
  {
    path: "/user-detail-temp/:id/:page",
    component: UserDetailpageTemp,
  },
  {
    path: "/logs-list",
    component: UserLogsPage,
    allowedRoles: ["0", "1"],
  },


];

export default userRoutes;
