import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Stack,
  FormControlLabel,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Check } from "@mui/icons-material";
interface TableCellWithOverflowProps {
  children: React.ReactNode;
  widthProp: string;
  isHeader?: boolean;
  isColumnOne?: boolean;
  isFeatureRow?: boolean;
}

const TableCellWithOverflow: React.FC<TableCellWithOverflowProps> = ({
  children,
  widthProp,
  isHeader = false, // 기본값은 false
  isColumnOne = false,
  isFeatureRow = false,
}) => (
  <TableCell
    sx={{
      maxWidth: "650px",
      width: widthProp,
      // textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      border: "1px dashed #ccc",
      textAlign: "center",
      // padding:"15px"
      fontFamily: "'Nanum Gothic'", // isHeader가 true일 때만 글꼴 적용
      fontSize: isFeatureRow ? "20px" : isHeader ? "20px" : "16px", // isHeader가 true일 때만 글자 크기 적용
      fontWeight: isHeader || isColumnOne ? "bold" : undefined, // Modify this line// isHeader가 true일 때만 글자 굵기 적용
      color: isHeader || isFeatureRow ? "#0057af" : "inherit",
      backgroundColor: isFeatureRow ? "aliceblue" : "inherit", // aliceblue
    }}
  >
    {children}
  </TableCell>
);

type PricingTier = {
  minUsers: number;
  maxUsers: number;
  pricePerUser: number;
};

const pricingTiers: PricingTier[] = [
  { minUsers: 1, maxUsers: 10, pricePerUser: 5000 },
  { minUsers: 11, maxUsers: 30, pricePerUser: 4000 },
  { minUsers: 31, maxUsers: 50, pricePerUser: 3000 },
  { minUsers: 51, maxUsers: 100, pricePerUser: 2000 },
];

const PaymentPage: React.FC = () => {
  const navigate = useNavigate();
  const [planType, setPlanType] = useState("monthly");
  console.log("paymentPage 렌더링");
  const rows = [
    {
      column1: "결제 방식",
      column2: "구독형",
      column3: "구입형",
    },
    {
      column1: "도입 가능 PC수량",
      column2: "1 ~ 무제한",
      column3: "무제한",
    },
    {
      column1: "라이선스 기간",
      column2: "월,반기,년",
      column3: "영구사용",
    },
    {
      column1: "도입 소요기간",
      column2: "결제 즉시 사용",
      column3: "고객 환경에 따라 평균 1개월 이상",
    },
    {
      column1: "제공 기능",
      column2: "",
      column3: "",
    },
    {
      column1: "MS Windows Hello 연동",
      column2: "제공",
      column3: "제공",
    },
    {
      column1: "MS or Google Authenticator OTP",
      column2: "제공",
      column3: "제공",
    },
    {
      column1: "커스터마이징",
      column2: "불가",
      column3: "제공(별도 비용 발생)",
    },
    {
      column1: "이상징후 알람 기능",
      column2: "불가",
      column3: "제공",
    },
    {
      column1: "사용자 PC 강제 잠금 기능",
      column2: "불가",
      column3: "제공",
    },
  ];

  const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlanType((event.target as HTMLInputElement).value);
  };

  const calculatePrice = (price: number) =>
    planType === "annual" ? price * 0.8 : price;

  return (
    <>
      <Container sx={{ mt: 15 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            textAlign="center"
            marginTop={5}
            marginBottom={3}
            style={{ animation: "slide-up 1s ease-out" }}
          >
            <Stack spacing={5}>
              {/* 여기서 '2'는 테마의 spacing 단위를 기반으로 한 간격입니다. */}
              <Typography
                variant="h3"
                component="h3"
                sx={{
                  fontFamily: "'Nanum Gothic'",
                  fontWeight: 600,
                  // color: "#1976d2",
                  "@media (max-width: 800px)": {
                    fontSize: "25px", //
                  },
                }}
              >
                PC <span style={{ color: "#0057af" }}>보안</span>을 위한 최적의
                선택
              </Typography>
              <Typography
                variant="h4"
                component="h4"
                sx={{
                  fontFamily: "'Nanum Gothic'",
                  fontWeight: 900,
                  // color: "#1976d2",
                  "@media (max-width: 800px)": {
                    fontSize: "20px", //
                  },
                }}
              >
                <span style={{ color: "black" }}>LogVisor </span>{" "}
                <span style={{ color: "#0057af" }}> 임대형</span> vs{" "}
                <span style={{ color: "#0057af" }}>구축형</span> 요금제 비교
              </Typography>
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                gap: 5,
                marginBottom: 3,
                marginTop: 10,
                flexWrap: "wrap",
              }}
            >
              <Card
                sx={{
                  minWidth: 350,
                  borderRadius: "20px",
                  backgroundColor: "grey.50",
                  color: "grey.700",
                  transition: "0.3s", // 부드러운 전환 효과를 위해 추가
                  "&:hover": {
                    backgroundColor: "white", // 마우스를 올렸을 때의 배경색
                    color: "black",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // 마우스를 올렸을 때 그림자 효과
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        color: "#0057af",
                      }}
                    >
                      임대형
                    </Typography>
                    <CardMedia
                      component="img"
                      height="60"
                      image="/images/cloud-removebg-preview.png"
                      alt="Image 1"
                      style={{ objectFit: "contain" }}
                    />

                    <Typography variant="body1" color="text.secondary">
                      Cloud SaaS
                    </Typography>
                  </CardContent>
                  <CardContent sx={{ textAlign: "start" }}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        fontFamily: "'Nanum Gothic'",
                        fontWeight: "bold",
                        "@media (max-width: 700px)": {
                          fontSize: "13px", //
                        },
                        // fontSize:"16px"
                        // color: "#1976d2",
                      }}
                    >
                      <Check></Check>구축 비용이 부담스러운 소상공인, 중소 고객
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        fontFamily: "'Nanum Gothic'",
                        fontWeight: "bold",
                        "@media (max-width: 700px)": {
                          fontSize: "13px", //
                        },

                        // fontSize:"16px"
                        // color: "#1976d2",
                      }}
                    >
                      <Check></Check> 1PC부터 단계적으로 도입하고 싶은 고객
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        fontFamily: "'Nanum Gothic'",
                        fontWeight: "bold",
                        "@media (max-width: 700px)": {
                          fontSize: "13px", //
                        },
                        // fontSize:"16px"
                        // color: "#1976d2",
                      }}
                    >
                      <Check></Check> 전산담당부서 구축이 부담스러운 고객
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        // variant="outlined"
                        onClick={() => navigate("/sign-up")}
                        sx={{
                          width: 150,
                          height: 40,
                          fontWeight: "bold",
                          borderRadius: "20px",
                          fontSize: 18,
                          fontFamily: "'Nanum Gothic'",
                          color: "white",
                          backgroundColor: "#0057af", // Default background color
                          "&:hover": {
                            backgroundColor: "#1976d2", // Background color on hover
                            color: "white",
                          },
                        }}
                      >
                        체험하기
                      </Button>
                    </Box>
                  </CardContent>
                </Box>
              </Card>

              <Typography
                variant="h4"
                component="h4"
                style={{
                  fontFamily: "'Nanum Gothic'",
                  fontWeight: 500,
                  color: "#1976d2",
                }}
              >
                vs
              </Typography>
              <Card
                sx={{
                  minWidth: 350,
                  borderRadius: "20px",
                  backgroundColor: "grey.50",
                  color: "grey.700",
                  transition: "0.3s", // 부드러운 전환 효과를 위해 추가
                  "&:hover": {
                    backgroundColor: "white", // 마우스를 올렸을 때의 배경색
                    color: "black",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // 마우스를 올렸을 때 그림자 효과
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        color: "#0057af",
                      }}
                    >
                      구축형
                    </Typography>
                    <CardMedia
                      component="img"
                      height="60"
                      image="/images/onpremise.png"
                      alt="Image 1"
                      style={{ objectFit: "contain" }}
                    />

                    <Typography variant="body1" color="text.secondary">
                      On-Premise
                    </Typography>
                  </CardContent>
                  <CardContent sx={{ textAlign: "start" }}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        fontFamily: "'Nanum Gothic'",
                        fontWeight: "bold",
                        "@media (max-width: 700px)": {
                          fontSize: "13px", //
                        },
                        // fontSize:"16px"
                        // color: "#1976d2",
                      }}
                    >
                      <Check></Check> 기능 추가,변경 등 커스텀이 필요한 고객
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        fontFamily: "'Nanum Gothic'",
                        fontWeight: "bold",
                        "@media (max-width:700px)": {
                          fontSize: "13px", //
                        },
                        // fontSize:"16px"
                        // color: "#1976d2",
                      }}
                    >
                      <Check></Check> 다양한 생체정보 인증 수단이 필요한 고객
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                      component="div"
                      sx={{
                        fontFamily: "'Nanum Gothic'",
                        fontWeight: "bold",
                        // fontSize:"16px"
                        // color: "#1976d2",
                        "@media (max-width: 700px)": {
                          fontSize: "13px", //
                        },
                      }}
                    >
                      <Check></Check> SSO, ERP 연동 등이 필요한 고객
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        // variant="outlined"
                        onClick={() => navigate("/payment-board-register")}
                        sx={{
                          width: 150,
                          height: 40,
                          fontWeight: "bold",
                          borderRadius: "20px",
                          fontSize: 18,
                          fontFamily: "'Nanum Gothic'",
                          color: "white",
                          backgroundColor: "#0057af", // Default background color
                          "&:hover": {
                            backgroundColor: "#1976d2", // Background color on hover
                            color: "white",
                          },
                        }}
                      >
                        도입 문의
                      </Button>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </Box>
          </Box>
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  marginTop: 5,
                  fontFamily: "'Nanum Gothic'",
                  fontWeight: "bold",
                  "@media (max-width: 700px)": {
                    fontSize: "13px", //
                  },
                }}
              >
                임대형 요금제를 선택해 보세요
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                인원수에 따라 요금이 다릅니다.
              </Typography>
            </Box>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                결제 플랜을 선택해주세요!
              </FormLabel>
              <RadioGroup
                row
                aria-label="payment plan"
                name="paymentPlan"
                value={planType}
                onChange={handlePlanChange}
              >
                <FormControlLabel
                  value="monthly"
                  control={<Radio />}
                  label="월간 플랜"
                />
                <FormControlLabel
                  value="annual"
                  control={<Radio />}
                  label="연간 플랜 (20% 할인)"
                />
              </RadioGroup>
            </FormControl>

            <Grid
              container
              spacing={2}
              sx={{ marginTop: 3 }}
              justifyContent="center"
            >
              {pricingTiers.map((tier, index) => (
                <Grid item key={index}>
                  <Card
                    sx={{
                      minWidth: "200px",
                      // width: "285px",
                      borderRadius: 4,
                    }}
                  >
                    <CardContent
                      sx={{
                        minHeight: "300px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{ fontWeight: "bold" }}
                        >
                          {tier.minUsers} ~ {tier.maxUsers} Users
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", mr: 1 }}
                          >
                            {calculatePrice(tier.pricePerUser).toLocaleString()}{" "}
                            원
                          </Typography>
                          <Typography variant="body2">/ 월별, 1인당</Typography>
                        </Box>

                        <Typography variant="body2" color="textSecondary">
                          부가세 포함
                        </Typography>
                      </Box>
                      <Box
                        sx={
                          {
                            // display: "flex",
                            // justifyContent: "end",
                            // marginTop: 4,
                          }
                        }
                      >
                        <Button
                          variant="contained"
                          // variant="outlined"
                          onClick={() => navigate("/sign-up")}
                          sx={{
                            width: 150,
                            height: 40,
                            fontWeight: "bold",
                            borderRadius: "20px",
                            fontSize: 18,
                            fontFamily: "'Nanum Gothic'",
                            color: "white",
                            backgroundColor: "#0057af", // Default background color
                            "&:hover": {
                              backgroundColor: "#1976d2", // Background color on hover
                              color: "white",
                            },
                          }}
                        >
                          체험하기
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
              <Grid item>
                <Card
                  sx={{
                    minWidth: "200px",
                    // width: "285px",
                    borderRadius: 4,
                  }}
                >
                  <CardContent
                    sx={{
                      minHeight: "300px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: "bold" }}
                      >
                        100+ Users
                      </Typography>
                      <Typography variant="h6">별도 문의</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: 4,
                        }}
                      ></Box>

                      <Box
                        sx={{
                          // display: "flex",
                          // justifyContent: "end",
                          marginTop: 7,
                        }}
                      >
                        <Button
                          variant="contained"
                          // variant="outlined"
                          onClick={() => navigate("/payment-board-register")}
                          sx={{
                            width: 150,
                            height: 40,
                            fontWeight: "bold",
                            borderRadius: "20px",
                            fontSize: 18,
                            fontFamily: "'Nanum Gothic'",
                            color: "white",
                            backgroundColor: "#0057af", // Default background color
                            "&:hover": {
                              backgroundColor: "#1976d2", // Background color on hover
                              color: "white",
                            },
                          }}
                        >
                          도입 문의
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
          <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: "center", fontWeight: "bold", my: 10 }}
          >
            상세 기능 비교
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 20 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "aliceblue" }}>
                <TableRow>
                  <TableCellWithOverflow widthProp="20%" isHeader>
                    구분
                  </TableCellWithOverflow>
                  <TableCellWithOverflow widthProp="40%" isHeader>
                    <img
                      src="/images/cloud-removebg-preview.png"
                      alt="Cloud"
                      style={{ width: "50px", marginRight: "15px" }}
                    />{" "}
                    임대형
                  </TableCellWithOverflow>
                  <TableCellWithOverflow widthProp="40%" isHeader>
                    <img
                      src="/images/onpremise.png"
                      alt="onpremise"
                      style={{ width: "50px", marginRight: "15px" }}
                    />
                    구축형
                  </TableCellWithOverflow>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    hover
                    // sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  >
                    <TableCellWithOverflow
                      widthProp="20%"
                      isColumnOne
                      isFeatureRow={row.column1 === "제공 기능"}
                    >
                      {row.column1}
                    </TableCellWithOverflow>
                    <TableCellWithOverflow
                      widthProp="40%"
                      isFeatureRow={row.column1 === "제공 기능"}
                    >
                      {row.column2}
                    </TableCellWithOverflow>
                    <TableCellWithOverflow
                      widthProp="40%"
                      isFeatureRow={row.column1 === "제공 기능"}
                    >
                      {row.column3}
                    </TableCellWithOverflow>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </>
  );
};

export default PaymentPage;
