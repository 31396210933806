// src/pages/DashBoardPage.tsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Container, IconButton } from "@mui/material";
import BarChartComponent from "../../components/dashBoard/BarChartComponent";
import { RootState } from "@/index";
import { AnyAction } from "redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useNavigate, useParams } from "react-router-dom";
import UserInfoForm from "../../components/user/userInfoForm";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TableViewIcon from "@mui/icons-material/TableRows";
import {
  FetchCountLoginsPerDateOutOfWorkHoursByUserId,
  fetchCountAnomaliesPerDateByUserId,
  fetchCountLoginsPerDateByUserId,
  fetchCountWeekendLoginsDuringWorkHoursByUserId,
  fetchCountWeekendLoginsOutOfWorkHoursByUserId,
  fetchCountWeekendLoginsPerDateByUserId,
} from "../../redux/dashBoard/DashBoardActions";
import { fetchUserInfo } from "../../redux/user/userActions";
import { User } from "../../types/userTypes";

const DashboardDetailPage: React.FC = () => {
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const LoginsPerDate = useSelector(
    (state: RootState) => state.dashBoard.LoginsPerDate
  );
  const AnomaliesPerDate = useSelector(
    (state: RootState) => state.dashBoard.AnomaliesPerDate
  );
  const LoginsPerDateOutOfWorkHours = useSelector(
    (state: RootState) => state.dashBoard.LoginsPerDateOutOfWorkHours
  );
  const WeekendLoginsPerDate = useSelector(
    (state: RootState) => state.dashBoard.WeekendLoginsPerDate
  );
  const WeekendLoginsDuringWorkHours = useSelector(
    (state: RootState) => state.dashBoard.WeekendLoginsDuringWorkHours
  );
  const WeekendLoginsOutOfWorkHours = useSelector(
    (state: RootState) => state.dashBoard.WeekendLoginsOutOfWorkHours
  );

  const { id } = useParams<{ id: string }>();
  const userId = String(id);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState<User | null>(null);

  useEffect(() => {
    dispatch(fetchCountLoginsPerDateByUserId(userId));
    dispatch(fetchCountAnomaliesPerDateByUserId(userId));
    dispatch(FetchCountLoginsPerDateOutOfWorkHoursByUserId(userId));
    dispatch(fetchCountWeekendLoginsPerDateByUserId(userId));
    dispatch(fetchCountWeekendLoginsDuringWorkHoursByUserId(userId));
    dispatch(fetchCountWeekendLoginsOutOfWorkHoursByUserId(userId));
    if (userId) {
      console.log("useEffect1- fetchUserInfo(id)호출 id:", id);
      dispatch(fetchUserInfo(userId))
        .then((data) => {
          console.log("setIsLoading작동"); //상태 업데이트가 화면에 반영되기 전에 실행됩니다.
          setIsLoading(false); //비동기적 처리
          setFetchedData(data); // 비동기적 처리 가져온 데이터를 상태에 저장
          console.log(" useEffect1 - 전달받은 data: ", data);
        })
        .catch((error: any) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [dispatch, userId]);

  const handleChartClick = (chartType: string) => {
    navigate(`/user-detail/${userId}?type=${chartType}&page=1`);
  };

  const handleToggleView = () => {
    navigate(`/user-detail/${userId}?page=1`);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="container mt-3" style={{ minWidth: "300px" }}>
          {/*맨윗줄 유저정보랑  기간설정*/}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around" 
            mb={2}
          >
            <UserInfoForm fetchedData={fetchedData}></UserInfoForm>
            <Box>
              <IconButton color={"primary"}>
                <AssessmentIcon fontSize="large" />
              </IconButton>
              <IconButton onClick={handleToggleView} color={"default"}>
                <TableViewIcon fontSize="large" />
              </IconButton>
            </Box>
            <Box></Box>
          </Box>
          {/* 메인컨텐츠 */}
          <Box>
            <div className="container mt-3" style={{ minWidth: "300px" }}>
              <Container>
                <Box sx={{ flexGrow: 1, mt: 5, mb: 10 }}>
                  <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                      <BarChartComponent
                        title="일별 로그인 현황"
                        data={LoginsPerDate}
                        onClick={() => handleChartClick("daily-logins")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <BarChartComponent
                        title="일별 이상징후 현황"
                        data={AnomaliesPerDate}
                        onClick={() => handleChartClick("daily-anomalies")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <BarChartComponent
                        title="일별 근무시간 외 로그인 현황"
                        data={LoginsPerDateOutOfWorkHours}
                        onClick={() => handleChartClick("off-hours-login")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <BarChartComponent
                        title="주말 로그인 현황"
                        data={WeekendLoginsPerDate}
                        onClick={() => handleChartClick("weekend-logins")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <BarChartComponent
                        title="주말 근무시간 내 로그인 현황"
                        data={WeekendLoginsDuringWorkHours}
                        onClick={() =>
                          handleChartClick("weekend-work-hours-logins")
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <BarChartComponent
                        title="주말 근무시간 외 로그인 현황"
                        data={WeekendLoginsOutOfWorkHours}
                        onClick={() =>
                          handleChartClick("weekend-off-hours-logins")
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </div>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default DashboardDetailPage;
