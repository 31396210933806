// userUtils.ts

import { UserAllLoginDetailData } from "../../types/userTypes";

export const formatDateTime = (dateString: string): string => {
  const date = new Date(dateString);

  // 날짜 부분 형식화 (2024.10.28.(월))
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    weekday: "short", // 요일의 축약형 (월, 화, 수 등)
  };
  const formattedDate = date.toLocaleDateString("ko-KR", dateOptions).replace(/ /g, '');

  // 시간 부분 형식화 (15:05:48)
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  // 최종 결과 조합
  return `${formattedDate} ${formattedTime}`;
};




// 요일을 반환하는 함수
export const getFormattedDate = (dateString: string): string => {
  const date = new Date(dateString);

  // 날짜 옵션 설정
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    weekday: "short", // 'short', 'long', 'narrow' 중 하나로 지정
  };

  // 'ko-KR' 로케일을 사용하여 날짜 형식화
  let formattedDate = date.toLocaleDateString("ko-KR", options);
  const parts = formattedDate.split(" "); // 공백을 기준으로 분리
  if (parts.length > 3) {
    // 정상적으로 분리되었다면
    // 배열의 마지막 부분이 요일
    //한글버전
    const weekday = parts.pop(); // 요일 부분을 배열에서 제거하고 변수에 저장
    formattedDate = `${parts.join("")}${weekday}`; // 날짜와 요일 조합
  }

  // let formattedDate = date.toLocaleDateString("en-US", options);
  //   // 날짜와 요일 정보 분리
  //   const parts = formattedDate.split(', '); // 미국식 날짜에서는 일반적으로 날짜와 요일이 콤마와 공백으로 구분됩니다
  //   if (parts.length > 1) { // 정상적으로 분리되었다면
  //     const weekday = parts[0];  // 배열의 첫 부분이 요일
  //     const datePart = parts[1]; // 나머지 부분이 날짜
  //     formattedDate = `${datePart} (${weekday})`;  // 날짜와 요일 조합하여 재구성
  //   }

  // console.log("formmattedDate",formattedDate);
  // 괄호를 사용해 요일을 표시하도록 문자열 수정

  return formattedDate;
};

// 주말 체크 함수
export const isWeekend = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDay();
  return day === 0 || day === 6; // 0: 일요일, 6: 토요일
};

// 근무 시간 외 체크 함수
export const isOutsideWorkingHours = (dateTimeString: string | null | undefined) => {
  if (!dateTimeString) {
    return false; // dateTimeString이 null 또는 undefined일 때 기본값으로 false 반환
  }
  const date = new Date(dateTimeString);
  const year = date.getFullYear();

  // 1900년 또는 1970년이면 근무시간외로 치지 않음(기본값이라서)
  if (year === 1900 || year === 1970) {
    return false;
  }
  const hours = date.getHours();
  return hours < 9 || hours >= 18; // 9시 이전 또는 18시 이후
};


// userUtils.ts에서 getHighlightStyle 함수 수정
export const getHighlightStyle = (
  isWeekendLogin: boolean,
  isOutsideWorkingHours: boolean
) => {
  let highlightStyle = {};

  if (isWeekendLogin) {
    highlightStyle = isOutsideWorkingHours
      ? { color: "red", fontWeight: "bold" }//주말 시간외
      : { color: "red", fontWeight: "bold" };//주말시간내
  } else if (isOutsideWorkingHours) {//평일 시간외
    highlightStyle = { color: "red", fontWeight: "bold" };
  } else {
    highlightStyle = { color:"", fontWeight: "" }; // 평일 정상 시간 로그인에 대한 스타일
  }

  return highlightStyle;
};


export function convertToCSV(data: UserAllLoginDetailData[]): string {
  // CSV 헤더 추가
  const headers = "Login Date,Login Type,IP Address";
  
  // 데이터 행을 생성
  const rows = data.map((user) => {
    const formattedDateTime = formatDateTime(user.loginDateTime);
    const isWeekendLogin = isWeekend(user.loginDateTime);
    const isOutsideHours = isOutsideWorkingHours(user.loginDateTime);
    const status = isWeekendLogin
      ? isOutsideHours ? "주말 시간외" : "주말"
      : isOutsideHours ? "시간외" : "정상";

    // CSV 형식에 맞게 각 필드를 쌍따옴표로 감싸서 이스케이프 처리
    const csvRow = [
      `"${formattedDateTime}"`,
      `"${user.loginType}"`,
      `"${user.pcIp}"`,
    ];

    return csvRow.join(',');
  });

  // 모든 행을 개행 문자로 연결하여 완성된 CSV 문자열 생성
  return [headers, ...rows].join('\n');
}

export function convertAllLogsToCSV(data: UserAllLoginDetailData[]): string {
  // CSV 헤더 추가
  const headers = "ID,Company,User Name,Login Date,Login Type,IP Address";
  
  // 데이터 행을 생성
  const rows = data.map((user) => {
    const formattedDateTime = formatDateTime(user.loginDateTime);
    const isWeekendLogin = isWeekend(user.loginDateTime);
    const isOutsideHours = isOutsideWorkingHours(user.loginDateTime);
    const status = isWeekendLogin
      ? isOutsideHours ? "주말 시간외" : "주말"
      : isOutsideHours ? "시간외" : "정상";

    // CSV 형식에 맞게 각 필드를 쌍따옴표로 감싸서 이스케이프 처리
    const csvRow = [
      `"${user.userId}"`,
      `"${user.companyName}"`,
      `"${user.ein}"`,
      `" ${formattedDateTime}"`,
      `"${user.loginType}"`,
      `"${user.pcIp}"`,
    ];

    return csvRow.join(',');
  });

  // 모든 행을 개행 문자로 연결하여 완성된 CSV 문자열 생성
  return [headers, ...rows].join('\n');
}

// export function convertToCSV(data: UserAllLoginDetailData[]): string {
//   const headers = Object.keys(data[0]).join(',');
//   const rows = data.map(row =>
//     Object.values(row).map(field =>
//       typeof field === 'string' ? `"${field.replace(/"/g, '""')}"` : field
//     ).join(',')
//   );
//   return [headers, ...rows].join('\n');
// }

export function downloadCSV(csvString: string, filename: string = 'download.csv'): void {
  const BOM = '\uFEFF'; // UTF-8 Byte Order Mark
  const blob = new Blob([BOM+csvString], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
