import { Box, Container,  Typography } from "@mui/material";

const LogvisorPage = () => {
  return (
    <>
      <Container sx={{ mt: 5 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            textAlign="center"
            marginTop={5}
            marginBottom={3}
            style={{ animation: "slide-up 1s ease-out" }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                // fontSize: "24px",
                fontFamily: "'Nanum Gothic'",
                fontWeight: "bold",
                // background: "linear-gradient(180deg, skyblue, blue, darkblue)",
                // WebkitBackgroundClip: "text",
                // WebkitTextFillColor: "transparent",
                // backgroundClip: "text",
                // color: "transparent", // 비웹킷 브라우저용
                // color: "black",
                fontStyle: "italic",
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              <span style={{ color: " #0057af", marginRight: "15px" }}>
                LogVisor란?{" "}
              </span>{" "}
              Log(기록) + superVisor(감독관)의 융합 합성어입니다.
            </Typography>
          </Box>
          <Box
            //   textAlign="center"
            marginTop={5}
            marginBottom={3}
            sx={{
              display: "flex",
              gap: "30px",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              "@media (max-width: 1000px)": {
                flexDirection: "column", // 화면 너비가 1000px 이하일 때 세로로 정렬
              },
            }}
            style={{ animation: "slide-up 1s ease-out" }}
          >
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontSize: "19px",
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                color: "black",
                lineHeight: "2", // 이 부분을 추가하거나 조정합니다.
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              LogVisor는<br></br> “도둑이 문을 열고 들어 가지 못한다면 어떠한
              일도 일어나지 않는다＂라는 상식에서 출발했습니다.<br></br>
              <br></br> LogVisor는
              <br></br> PC(Device) 로그인 전용 솔루션으로서 안티바이러스 백신과
              같이 보편적인 국민보안솔루션으로 성장하는 것은 물론 전세계의
              표준이 되는 것이 목표입니다.
            </Typography>

            <Box
              component="img"
              src="/images/introduce/pc-question.png"
              alt=""
              sx={{
                width: 550, // 기본 너비 설정
                marginBottom: "",
                "@media (max-width: 900px)": {
                  width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
                },
              }}
            />
          </Box>
        </Box>
      </Container>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "red",
          // padding: 5,
        }}
        // textAlign="center"
        marginTop={5}
        marginBottom={3}
        style={{ animation: "slide-up 1s ease-out" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage:
              "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // backgroundImage: "linear-gradient(to bottom right, #E8F2F2, #D5E3E2)",
            // padding: 10,
            width: "100%",
            padding: 0,
            gap: 15,
            marginBottom: 10,
            "@media (max-width: 1250px)": {
              flexDirection: "column", // 화면 너비가 1000px 이하일 때 세로로 정렬
              paddingTop: 5,
              paddingBottom: 5,
              gap: 0,
            },
          }}
        >
          <Box
            sx={{
              paddingLeft: "5vw",
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                fontSize: "28px",
                // color: "MediumBlue",
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              기존 PC 로그인의 근본적인 취약점
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                // fontSize: "24px",
                // color: "MediumBlue",
                // color:"grey",
                // color:"darkgreen",
                marginBottom: 10,
                lineHeight: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              기존 PC 로그인 방식은 password에 의존하여 비밀번호가<br></br>{" "}
              유출될 위험성이 있으며 PC 부정 사용자를 탐지하는 방법이
              없었습니다.
            </Typography>
          </Box>
          <Box
            component="img"
            src="/images/introduce/3page.jpg"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>
        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontFamily: "'Nanum Gothic'",
            fontWeight: 900,
            // fontSize: "30px",
            color: " #0057af",
            marginBottom: 10,
            "@media (max-width: 800px)": {
              fontSize: "20px", //
            },
          }}
        >
          왜 PC 로그인 보안이 필요할까요?
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage:
              "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            padding: 0,
            gap: 15,
            marginBottom: 10,
            "@media (max-width: 1250px)": {
              flexDirection: "column", // 화면 너비가 1000px 이하일 때 세로로 정렬
              paddingTop: 5,
              paddingBottom: 5,
              gap: 0,
            },
          }}
        >
          <Box
            sx={{
              paddingLeft: "5vw",
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                fontSize: "30px",
                // color: "MediumBlue",
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              PC 로그인 보안의 필요성
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                // fontSize: "30px",
                // color: "MediumBlue",
                // color:"grey",
                marginBottom: 10,
                lineHeight: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              보안 위협의 고도화로 금융감독원, 과학기술정보통신부의<br></br>{" "}
              보안 정책에 맞는 PC로그인 보안을 갖춰야 합니다.
              <br></br>
            </Typography>
          </Box>
          <Box
            component="img"
            src="/images/introduce/4page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>

        <Typography
          variant="h4"
          component="h4"
          sx={{
            fontFamily: "'Nanum Gothic'",
            fontWeight: 900,
            // fontSize: "30px",
            color: " #0057af",
            marginBottom: 10,
            "@media (max-width: 800px)": {
              fontSize: "20px", //
            },
          }}
        >
          PC 로그인 보안 솔루션 시장 진출의 필요성
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage:
              "linear-gradient(to right, #EFF7F7, #D5E3E2, #EFF7F7)",
            // padding: 10,
            width: "100%",
            padding: 0,
            gap: 15,
            marginBottom: 10,
            "@media (max-width: 1250px)": {
              flexDirection: "column", // 화면 너비가 1000px 이하일 때 세로로 정렬
              paddingTop: 5,
              paddingBottom: 5,
              gap: 0,
            },
          }}
        >
          <Box
            sx={{
              paddingLeft: "5vw",
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                fontSize: "28px",
                // color: "MediumBlue",
                marginBottom: 10,
                "@media (max-width: 800px)": {
                  fontSize: "20px", //
                },
              }}
            >
              PC 로그인 보안 솔루션 시장 진출의 필요성
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Nanum Gothic'",
                fontWeight: 900,
                // fontSize: "30px",
                // color: "MediumBlue",
                // color:"grey",
                marginBottom: 10,
                lineHeight: 2,
                "@media (max-width: 800px)": {
                  fontSize: "15px", //
                },
              }}
            >
              과기정통부의 제로트러스트 가이드라인, 금융감독원의 내부통제 강화에
              따라    PC 로그인 2단계 인증이 필수가 되었습니다.
            </Typography>
          </Box>
          <Box
            component="img"
            src="/images/introduce/5page.png"
            alt=""
            sx={{
              width: 900, // 기본 너비 설정
              marginBottom: "",
              "@media (max-width: 900px)": {
                width: "100%", // 화면 너비가 900px 이하일 때 너비를 100%로 변경
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default LogvisorPage;
