import React from "react";
import { Box, Button } from "@mui/material";
import { PagedResponse, UserLastLoginData } from "../../types/userTypes";
import UserCardForm from "./userCardForm";
import PaginationByServer from "../PagenationByServer";
import { useNavigate } from "react-router-dom";
import { formatDateTime, getFormattedDate, getHighlightStyle, isOutsideWorkingHours, isWeekend } from "./userUtils";

interface UserListFormProps {
  pagedUsers: PagedResponse<UserLastLoginData> | null;
  handlePageChange: (pageNumber: number) => void;
}

const UserListForm: React.FC<UserListFormProps> = ({
  pagedUsers,
  handlePageChange,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {/* 사용자 카드 */}
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        p={1}
        mx={3}
        my={2}
        sx={{
          maxWidth: "100%",
          minHeight: "70vh",
          backgroundColor: "#f5f5f5",
          borderRadius: 7,
        }}
      >
        {pagedUsers && pagedUsers.content && pagedUsers.content.length > 0 ? (
          pagedUsers.content.map((user: UserLastLoginData, index) => {
            const formattedDateTime = formatDateTime(user.loginDateTime);
                  const isWeekendLogin = isWeekend(user.loginDateTime);
                  const isOutsideHours = isOutsideWorkingHours(user.loginDateTime);
            const highlightStyle = getHighlightStyle(
              isWeekendLogin,
              isOutsideHours
            ); 
            return <UserCardForm user={user} key={user.userId} style={highlightStyle} date={formattedDateTime}/>;
          })
        ) : (
          <div style={{ padding: "20px" }}>유저가 없습니다.</div>
        )}
      </Box>

      {/* 페이지네이션과 뒤로가기 버튼 */}
      <Box display="flex" justifyContent="space-around" alignItems="center">
        <Box />
        {pagedUsers ? (
          <PaginationByServer
            pagedUnits={pagedUsers}
            handlePageChange={handlePageChange}
          />
        ) : null}
        <Button
          variant="contained"
          onClick={() => navigate("/user-list?page=1")}
          sx={{ mt: 2, mb: 2 }}
        >
          전체목록
        </Button>
      </Box>
    </>
  );
};

export default UserListForm;
