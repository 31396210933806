import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { PagedResponse, UserLastLoginData } from "../../types/userTypes";
import PaginationByServer from "../PagenationByServer";
import { formatDateTime, getFormattedDate, getHighlightStyle, isOutsideWorkingHours, isWeekend } from "./userUtils";

interface UserListTableFormProps {
  pagedUsers: PagedResponse<UserLastLoginData> | null;
  handlePageChange: (pageNumber: number) => void;
}

const UserListTableForm: React.FC<UserListTableFormProps> = ({
  pagedUsers,
  handlePageChange,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ width: "100%", minWith: "200px" }}>
        <TableContainer component={Paper} sx={{ marginBottom: 5 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Company
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  User Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Last Login
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Login Type
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  IP address
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedUsers &&
              pagedUsers.content &&
              pagedUsers.content.length > 0 ? (
                pagedUsers.content.map((user: UserLastLoginData, index) => {
                  const formattedDateTime = formatDateTime(user.loginDateTime);
                  const isWeekendLogin = isWeekend(user.loginDateTime);
                  const isOutsideHours = isOutsideWorkingHours(user.loginDateTime);
                  const highlightStyle = getHighlightStyle(
                    isWeekendLogin,
                    isOutsideHours
                  ); 
                  return (
                    <TableRow
                      key={`${user.userId}-${user.loginDateTime}-${index}`}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#f5f5f5", // You can change this color to any you like
                        },
                      }}
                      onClick={() =>
                        navigate(`/user-detail/${user.userId}?page=1`)
                      }
                    >
                      <TableCell align="center" sx={highlightStyle}>
                        {user.userId}
                      </TableCell>
                      <TableCell align="center" sx={highlightStyle}>
                        {user.companyName}
                      </TableCell>
                      <TableCell align="center" sx={highlightStyle}>
                        {user.ein}
                      </TableCell>
                      <TableCell align="center" sx={highlightStyle}>
                        {formattedDateTime}
                      </TableCell>
                      <TableCell align="center" sx={highlightStyle}>
                        {user.loginType}
                      </TableCell>
                      <TableCell align="center" sx={highlightStyle}>
                        {user.pcIp}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                  유저가 없습니다.
                </TableCell>
              </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* 페이지네이션과 뒤로가기 버튼 */}
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <Box />
          {pagedUsers ? (
            <PaginationByServer
              pagedUnits={pagedUsers}
              handlePageChange={handlePageChange}
            />
          ) : null}
          <Button
            variant="contained"
            onClick={() => navigate("/user-list?page=1")}
            sx={{ mt: 2, mb: 2 }}
          >
            전체목록
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UserListTableForm;
