import React, { Dispatch } from "react";
import { Button, Box } from "@mui/material";
import { fetchPagedUserList } from "../redux/user/userActions";
import { useDispatch } from "react-redux";
import { PagedResponse, UserLastLoginData } from "../types/userTypes";

interface PaginationProps<T> {
  pagedUnits: PagedResponse<T>;
  // navigate: (path: string) => void; // navigate 함수를 prop으로 추가
  // itemsPerPage:number;
  handlePageChange: (pageNumber: number) => void; // 페이지 변경 처리 함수
}

const PaginationByServer = <T,>({
  pagedUnits,
  handlePageChange,
}: PaginationProps<T>) => {
  //위 대신에 이걸로 해도됨
  //const PaginationByServer = <T,>(props: PaginationProps<T>): React.ReactElement => {
  //  const { pagedUnits, handlePageChange } = props;
  //

  const dispatch: Dispatch<any> = useDispatch();

  const pageNumbers = Array.from(
    { length: pagedUnits.endPage - pagedUnits.startPage + 1 },
    (_, i) => i + pagedUnits.startPage
  );
  console.log("pageNumbers:",pageNumbers)

  return (
    <>
      {pagedUnits.totalPages > 0 ? (
        <Box display="flex" justifyContent="center">
          {pagedUnits.hasPrevious && (
            <Button
              onClick={() => handlePageChange(pagedUnits.previousPageGroupEnd)}
              sx={{ mx: 5 }}
            >
              {"←이전"}
            </Button>
          )}
          {pageNumbers.map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              variant={
                pagedUnits.currentPage === pageNumber ? "contained" : "outlined"
              }
              sx={{ mx: 0.5, minWidth: "0px", width: "40px" }}
            >
              {pageNumber}
            </Button>
          ))}
          {pagedUnits.hasNext && (
            <Button
              onClick={() => handlePageChange(pagedUnits.nextPageGroupStart)}
              sx={{ mx: 5 }}
            >
              {"다음→"}
            </Button>
          )}
        </Box>
      ) : null}
    </>
  );
};

export default PaginationByServer;
