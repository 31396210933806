// User shape 정의

// TypeScript Interface for PagedResponse
export interface PagedResponse<T> {
  content: T[];
  currentPage: number;
  startPage: number;
  endPage: number;
  previousPageGroupEnd: number;
  nextPageGroupStart: number;

  hasPrevious: boolean;
  hasNext: boolean;

  totalPages: number;
  size: number;
}


export interface User {
  id?: number;
  userId:string;
  managerId?:string;
  ein:string;
  department:string;
  email: string;
  tel:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}

export interface UserUpdate {
  id?: number;
  userId:string;
  managerId?:string;
  ein?:string;
  department?:string;
  email?: string;
  tel?:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}
export interface UserLastLoginData{
  id?: number;
  userId:string;
  ein:string;
  companyName?:string;
  loginDateTime:string;
  loginType:string;
  pcIp:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}
export interface UserAllLoginDetailData{
  id?: number;
  userId:string;
  managerId?:string;
  companyName?:string;
  ein:string;
  department:string;
  email: string;
  tel:string;
  loginDateTime:string;
  loginType:string;
  pcIp:string;
  // role?: string;
  // provider?: string;
  // providerId?: string;
  // createDate?: Date;
}


export interface Company{
  companyName:string;
}

export interface ErrorResponse {
  message: string;
}

export interface UserState {
  loading: boolean;
  user: User | null;
  userList: UserLastLoginData[];
  pagedUsers: PagedResponse<UserLastLoginData> | null;
  userDetailLoginData: UserAllLoginDetailData[];
  pagedLogs: PagedResponse<UserAllLoginDetailData> | null;
  error: string | null;
  totalUsers:number;
  endDate:string |null;
}

export const FETCH_USERLIST_REQUEST = "FETCH_USERLIST_REQUEST";
export const FETCH_USERLIST_SUCCESS = "FETCH_USERLIST_SUCCESS";
export const FETCH_PAGED_USERLIST_SUCCESS = "FETCH_PAGED_USERLIST_SUCCESS";
export const FETCH_USERLIST_FAILURE = "FETCH_USERLIST_FAILURE";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_PAGED_USER_LOGS_SUCCESS = "FETCH_PAGED_USER_LOGS_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const FETCH_PAGED_ALL_LOGS_REQUEST = "FETCH_PAGED_ALL_LOGS_REQUEST";
export const FETCH_PAGED_ALL_LOGS_SUCCESS = "FETCH_PAGED_ALL_LOGS_SUCCESS";
export const FETCH_PAGED_ALL_LOGS_FAILURE = "FETCH_PAGED_ALL_LOGS_FAILURE";


export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const PWINIT_USER = "PWINIT_USER";
export const PWINIT_USER_SUCCESS = "PWINIT_USER_SUCCESS";
export const PWINIT_USER_FAILURE = "PWINIT_USER_FAILURE";

export const FETCH_TOTAL_USERS_COUNT_REQUEST = 'FETCH_TOTAL_USERS_COUNT_REQUEST';
export const FETCH_TOTAL_USERS_COUNT_SUCCESS = 'FETCH_TOTAL_USERS_COUNT_SUCCESS';
export const FETCH_TOTAL_USERS_COUNT_FAILURE = 'FETCH_TOTAL_USERS_COUNT_FAILURE';


export const FETCH_SUBSCRIPTION_REMAINING_REQUEST = 'FETCH_SUBSCRIPTION_REMAINING_REQUEST';
export const FETCH_SUBSCRIPTION_REMAINING_SUCCESS = 'FETCH_SUBSCRIPTION_REMAINING_SUCCESS';
export const FETCH_SUBSCRIPTION_REMAINING_FAILURE = 'FETCH_SUBSCRIPTION_REMAINING_FAILURE';


export const CLEAR_PAGED_USER_LOG = 'CLEAR_PAGED_USER_LOG';


type FetchUserListRequestAction = {
  type: typeof FETCH_USERLIST_REQUEST;
};

type FetchUserListSuccessAction = {
  type: typeof FETCH_USERLIST_SUCCESS;
  payload: UserLastLoginData[];
};
type FetchPagedUserListSuccessAction = {
  type: typeof FETCH_PAGED_USERLIST_SUCCESS;
  payload: PagedResponse<UserLastLoginData>;
};


type FetchUserListFailureAction = {
  type: typeof FETCH_USERLIST_FAILURE;
  payload: string;
};

interface FetchUserRequestAction {
  type: typeof FETCH_USER_REQUEST;
}

interface FetchUserSuccessAction {
  type: typeof FETCH_USER_SUCCESS;
  payload: UserAllLoginDetailData[];
}
interface FetchPagedUserLogsSuccessAction {
  type: typeof FETCH_PAGED_USER_LOGS_SUCCESS;
  payload: PagedResponse<UserAllLoginDetailData>;
}

interface FetchUserFailureAction {
  type: typeof FETCH_USER_FAILURE;
  error: string;
}


interface FetchPagedALLLogsRequestAction {
  type: typeof FETCH_PAGED_ALL_LOGS_REQUEST;
}

interface FetchPagedALLLogsSuccessAction {
  type: typeof FETCH_PAGED_ALL_LOGS_SUCCESS;
  payload: PagedResponse<UserAllLoginDetailData>;
}

interface FetchPagedALLLogsFailureAction {
  type: typeof FETCH_PAGED_ALL_LOGS_FAILURE;
  error: string;
}



interface DeleteUserAction {
  type: typeof DELETE_USER;
}
interface DeleteUserSuccessAction {
  type: typeof DELETE_USER_SUCCESS;
  payload: string;
}

interface DeleteUserFailtureAction {
  type: typeof DELETE_USER_FAILURE;
  error: string;
}

interface PwInitUserAction {
  type: typeof PWINIT_USER;
}
interface PwInitUserSuccessAction {
  type: typeof PWINIT_USER_SUCCESS;
  payload: string;
}

interface PwInitUserFailtureAction {
  type: typeof PWINIT_USER_FAILURE;
  error: string;
}



interface FetchTotalUsersRequestAction {
  type: typeof FETCH_TOTAL_USERS_COUNT_REQUEST;
}

interface FetchTotalUsersSuccessAction {
  type: typeof FETCH_TOTAL_USERS_COUNT_SUCCESS;
  payload: number; // 총 유저 수를 숫자로 받습니다.
}

interface FetchTotalUsersFailureAction {
  type: typeof FETCH_TOTAL_USERS_COUNT_FAILURE;
  payload: string; // 에러 메시지를 문자열로 받습니다.
}


interface FetchSubscriptionRemainingRequestAction {
  type: typeof FETCH_SUBSCRIPTION_REMAINING_REQUEST;
}

interface FetchSubscriptionRemainingSuccessAction {
  type: typeof FETCH_SUBSCRIPTION_REMAINING_SUCCESS;
  payload: string; // 총 유저 수를 숫자로 받습니다.
}

interface FetchSubscriptionRemainingFailureAction {
  type: typeof FETCH_SUBSCRIPTION_REMAINING_FAILURE;
  payload: string; // 에러 메시지를 문자열로 받습니다.
}

type ClearPagedUserLog = {
  type: typeof CLEAR_PAGED_USER_LOG;
};
export type UserActionTypes =
  | FetchUserListRequestAction
  | FetchUserListSuccessAction
  | FetchPagedUserListSuccessAction
  | FetchUserListFailureAction
  | FetchUserRequestAction
  | FetchPagedUserLogsSuccessAction
  | FetchUserSuccessAction
  | FetchUserFailureAction
  | FetchPagedALLLogsRequestAction
  | FetchPagedALLLogsSuccessAction
  | FetchPagedALLLogsFailureAction
  | DeleteUserAction
  | DeleteUserSuccessAction
  | DeleteUserFailtureAction
  | PwInitUserAction
  | PwInitUserSuccessAction
  | PwInitUserFailtureAction
  | FetchTotalUsersRequestAction
  | FetchTotalUsersSuccessAction
  | FetchTotalUsersFailureAction
  | FetchSubscriptionRemainingRequestAction
  | FetchSubscriptionRemainingSuccessAction
  | FetchSubscriptionRemainingFailureAction
  |ClearPagedUserLog;
