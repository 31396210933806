import React from "react";
import { Avatar, Box, Typography, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/reducers";
interface UserProfileComponentProps {
  followersCount: number; // 사원 수를 나타내는 prop
  endDate: string | null;
}
const UserProfileComponent: React.FC<UserProfileComponentProps> = ({
  followersCount,
  endDate,
}) => {
  const Manager = useSelector((state: RootState) => state.manager);
  // 날짜 계산 함수
  const calculateRemainingDays = (endDate: string | null) => {
    if (endDate) {
      const end = new Date(endDate).getTime();
      const now = new Date().getTime();
      const diff = end - now;
      const remainingDays = Math.max(
        0,
        Math.ceil(diff / (1000 * 60 * 60 * 24))+1
      );
      return remainingDays;
    }
    return 0;
  };

  const remainingDays = calculateRemainingDays(endDate);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          // gap: 0,
          // backgroundColor:'red'
        }}
      >
        <Box>
          <Typography variant="h6" sx={{}}>
            Hello,
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {Manager.manager?.managerId}
          </Typography>
        </Box>
        {/* <Avatar
          alt={Manager.manager?.managerId}
          src="/images/profile1.png"
          sx={{ width: 65, height: 65 }}
        /> */}
        <Box
          component="img"
          sx={{
            width: 120, // 너비 설정
            // height: 65, // 높이 설정
            // objectFit: "cover", // 이미지가 박스에 맞게 조정
          }}
          alt={Manager.manager?.managerId}
          // src="/images/JJokomi-removebg1.png"
          // src="/images/ci2-removebg-preview.png"
          src="/images/kiny_jjokomi-removebg-preview_waifu2x_art_noise1_scale.png"
          // src="/images/kiny1-removebg-preview.png"
        />
      </Box>
      <Divider sx={{ width: "100%", bgcolor: "grey.700", my: 5 }}></Divider>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="body1" sx={{ color: "grey" }}>
            사원 수:{" "}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Divider
              orientation="vertical"
              sx={{
                bgcolor: "purple",
                mr: 1,
                height: 25,
                width: 3,
              }}
            />
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {followersCount}{" "}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="body1" sx={{ color: "grey" }}>
            남은 기간:{" "}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Divider
              orientation="vertical"
              sx={{
                bgcolor: "green",
                mr: 1,
                height: 25,
                width: 3,
              }}
            />
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", display: "inline" }}
            >
              {remainingDays}{" "}
            </Typography>
            <Typography
              variant="body1"
              sx={{ display: "inline", fontWeight: "bold" }}
            >
              일{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserProfileComponent;
