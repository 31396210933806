// src/pages/DashBoardPage.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Container } from '@mui/material';
import BarChartComponent from '../../components/dashBoard/BarChartComponent';
import { FetchCountLoginsPerDateOutOfWorkHours, fetchCountAnomaliesPerDate, fetchCountLoginsPerDate, fetchCountWeekendLoginsDuringWorkHours, fetchCountWeekendLoginsOutOfWorkHours, fetchCountWeekendLoginsPerDate } from '../../redux/dashBoard/DashBoardActions';
import { RootState } from '@/index';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

const DashboardPage: React.FC = () => {
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const LoginsPerDate = useSelector((state: RootState) => state.dashBoard.LoginsPerDate);
  const AnomaliesPerDate = useSelector((state: RootState) => state.dashBoard.AnomaliesPerDate);
  const LoginsPerDateOutOfWorkHours = useSelector((state: RootState) => state.dashBoard.LoginsPerDateOutOfWorkHours);
  const WeekendLoginsPerDate = useSelector((state: RootState) => state.dashBoard.WeekendLoginsPerDate);
  const WeekendLoginsDuringWorkHours = useSelector((state: RootState) => state.dashBoard.WeekendLoginsDuringWorkHours);
  const WeekendLoginsOutOfWorkHours = useSelector((state: RootState) => state.dashBoard.WeekendLoginsOutOfWorkHours);

  
  useEffect(() => {
    dispatch(fetchCountLoginsPerDate());
    dispatch(fetchCountAnomaliesPerDate());
    dispatch(FetchCountLoginsPerDateOutOfWorkHours());
    dispatch(fetchCountWeekendLoginsPerDate());
    dispatch(fetchCountWeekendLoginsDuringWorkHours());
    dispatch(fetchCountWeekendLoginsOutOfWorkHours());

  }, [dispatch]);

  const handleChartClick = (chartType:string) => {
    navigate(`/logs-list?type=${chartType}&page=1`);
  };


  return (
    <Container>
      <Box sx={{ flexGrow: 1, mt: 5, mb:10}}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
          <BarChartComponent title="일별 로그인 현황" data={LoginsPerDate} onClick={() => handleChartClick('daily-logins')} />
          </Grid>
          <Grid item xs={12} md={6}>
          <BarChartComponent title="일별 이상징후 현황" data={AnomaliesPerDate} onClick={() => handleChartClick('daily-anomalies')} />
          </Grid>
          <Grid item xs={12} md={6}>
          <BarChartComponent title="일별 근무시간 외 로그인 현황" data={LoginsPerDateOutOfWorkHours} onClick={() => handleChartClick('off-hours-login')}/>
          </Grid>
          <Grid item xs={12} md={6}>
          <BarChartComponent title="주말 로그인 현황" data={WeekendLoginsPerDate} onClick={() => handleChartClick('weekend-logins')}/>
          </Grid>
          <Grid item xs={12} md={6}>
          <BarChartComponent title="주말 근무시간 내 로그인 현황" data={WeekendLoginsDuringWorkHours} onClick={() => handleChartClick('weekend-work-hours-logins')}/>
          </Grid>
          <Grid item xs={12} md={6}>
          <BarChartComponent title="주말 근무시간 외 로그인 현황" data={WeekendLoginsOutOfWorkHours} onClick={() => handleChartClick('weekend-off-hours-logins')}/>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default DashboardPage;
