import PaymentBoardListForm from "../../components/paymentBoard/paymentBoardListForm";
import React from "react";
import { RootState } from "@/index";
import { useSelector } from "react-redux";

const PaymentBoardListPage: React.FC = () => {
  const pagedArticles = useSelector((state: RootState) => state.paymentBoard.pagedArticles);


  console.log(" PaymentBoardListPage 렌더링")
  return (
    <>
      <div className="container mt-3 p-0" style={{ minWidth: "500px" }}>
        <PaymentBoardListForm pagedArticles={pagedArticles}/>
      </div>
    </>
  );
};

export default PaymentBoardListPage;
