// UserInfoPage.tsx
import { UserAllLoginDetailData, PagedResponse } from "../../types/userTypes";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import React from "react";
import PaginationByServer from "../PagenationByServer";
import {
  convertToCSV,
  downloadCSV,
  formatDateTime,
  getFormattedDate,
  getHighlightStyle,
  isOutsideWorkingHours,
  isWeekend,
} from "./userUtils";
interface UserDetailFormProps {
  pagedLogs: PagedResponse<UserAllLoginDetailData> | null;
  handlePageChange: (pageNumber: number) => void; // 페이지 번호 변경 함수
  handleResetList: () => void; // 사원 목록 리셋 함수
}
const UserDetailForm: React.FC<UserDetailFormProps> = ({
  pagedLogs,
  handlePageChange,
  handleResetList,
}) => {
  const handleDownloadCSV = () => {
    if (pagedLogs && Array.isArray(pagedLogs.content) && pagedLogs.content.length > 0) {
      const user = pagedLogs.content[0]; // 첫 번째 로그 항목에서 사용자 정보 가져오기
  
      // 로컬 시간으로 날짜와 시간 설정
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      const formattedDate = `${year}${month.toString().padStart(2, "0")}${day
        .toString()
        .padStart(2, "0")}-${hours.toString().padStart(2, "0")}${minutes
        .toString()
        .padStart(2, "0")}${seconds.toString().padStart(2, "0")}`;
  
      const currentPage = pagedLogs.currentPage || 1;
  
      // 파일 이름에 userId와 username을 포함
      const filename = `logs-${user.userId}-${user.ein}-${formattedDate}-page${currentPage}.csv`;
  
      const csvString = convertToCSV(pagedLogs.content);
      downloadCSV(csvString, filename);
    }
  };
  

  console.log("userDetailForm페이지 렌더링");

  if (!pagedLogs) {
    return <CircularProgress/>;
  }
  if (!Array.isArray(pagedLogs.content)) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ width: "100%", minWith: "200px" }}>
        <TableContainer component={Paper} sx={{ marginBottom: 5 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Login Time
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Login Type 
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  IP address
                </TableCell>
                {/* <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Status
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedLogs.content.map((user: UserAllLoginDetailData, index) => {
                const formattedDateTime = formatDateTime(user.loginDateTime);
                const isWeekendLogin = isWeekend(user.loginDateTime);
                const isOutsideHours = isOutsideWorkingHours(user.loginDateTime);
                const status = isWeekendLogin
                  ? isOutsideHours
                    ? "주말 시간외"
                    : "주말"
                  : isOutsideHours
                  ? "시간외"
                  : "정상";
                const highlightStyle = getHighlightStyle(
                  isWeekendLogin,
                  isOutsideHours
                );
                if(user.loginDateTime ==null){
                  return ;
                }
                return (
                  <TableRow
                    key={`${user.userId}-${user.loginDateTime}-${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" sx={highlightStyle}>
                    {formattedDateTime}
                    </TableCell>
                    <TableCell align="center" sx={highlightStyle}>
                      {user.loginType}
                    </TableCell>
                    <TableCell align="center" sx={highlightStyle}>
                      {user.pcIp}
                    </TableCell>
                    {/* <TableCell align="center" sx={highlightStyle}>
                      {status}
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          // justifyContent="flex-end"
          // flexWrap="wrap"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box />

          {pagedLogs ? (
            <PaginationByServer
              pagedUnits={pagedLogs}
              handlePageChange={handlePageChange}
            />
          ) : (
            ""
          )}

          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              onClick={handleDownloadCSV}
              sx={{ mt: 2, mb: 2 }}
            >
              CSV
            </Button>
            <Button
              variant="contained"
              onClick={handleResetList}
              sx={{ mt: 2, mb: 2 }} // 상단 및 하단 마진 적용
            >
              사원목록
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserDetailForm;
