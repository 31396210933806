import TechnicalBoardListForm from "../../components/technicalBoard/technicalBoardListForm";
import React from "react";
import { RootState } from "@/index";
import { useSelector } from "react-redux";

const TechnicalBoardListPage: React.FC = () => {
  const pagedArticles = useSelector((state: RootState) => state.technicalBoard.pagedArticles);

  console.log(" TechnicalBoardListPage 렌더링")
  return (
    <>
      <div className="container mt-3 p-0"  style={{ minWidth: "500px" }}>
        <TechnicalBoardListForm pagedArticles={pagedArticles}/>
      </div>
    </>
  );
};

export default TechnicalBoardListPage;
