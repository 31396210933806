export interface LoginsPerDate{
  // id?: number;
  // userId?:string;
  // ein:string;
  // loginDate:string;
  // loginTime:string;
  // loginType:string;
  // pcIp:string;
  loginDate:string;
  loginCount:number;

}

export interface AnomaliesPerDate{
  loginDate:string;
  loginCount:number;

}

export interface LoginsPerDateOutOfWorkHours{
  loginDate:string;
  loginCount:number;

}

export interface WeekendLoginsPerDate{
  loginDate:string;
  loginCount:number;

}

export interface WeekendLoginsDuringWorkHours{
  loginDate:string;
  loginCount:number;

}

export interface WeekendLoginsOutOfWorkHours{
  loginDate:string;
  loginCount:number;

}

export interface DashBoardState {
    loading: boolean;
    LoginsPerDate: LoginsPerDate[] | null;
    AnomaliesPerDate :AnomaliesPerDate[] |null;
    LoginsPerDateOutOfWorkHours :LoginsPerDateOutOfWorkHours[] |null;
    WeekendLoginsPerDate :WeekendLoginsPerDate[] |null;
    WeekendLoginsDuringWorkHours :WeekendLoginsDuringWorkHours[] |null;
    WeekendLoginsOutOfWorkHours :WeekendLoginsOutOfWorkHours[] |null;
    error: string | null;
  }

  //1일별 로그인
export const FETCH_COUNTLOGINSPERDATE_REQUEST = "FETCH_COUNTLOGINSPERDATE_REQUEST";
export const FETCH_COUNTLOGINSPERDATE_SUCCESS = "FETCH_COUNTLOGINSPERDATE_SUCCESS";
export const FETCH_COUNTLOGINSPERDATE_FAILURE = "FETCH_COUNTLOGINSPERDATE_FAILURE";

type FetchCountLoginsPerDateRequestAction = {
    type: typeof FETCH_COUNTLOGINSPERDATE_REQUEST;
  };
  
  type FetchCountLoginsPerDateSuccessAction = {
    type: typeof FETCH_COUNTLOGINSPERDATE_SUCCESS;
    payload: LoginsPerDate[];
  };
  type FetchCountLoginsPerDateFailureAction = {
    type: typeof FETCH_COUNTLOGINSPERDATE_FAILURE;
    payload: string;
  };


  //2 일별 이상징후
  export const FETCH_COUNTANOMALIESPERDATE_REQUEST = "FETCH_COUNTANOMALIESPERDATE_REQUEST";
  export const FETCH_COUNTANOMALIESPERDATE_SUCCESS = "FETCH_COUNTANOMALIESPERDATE_SUCCESS";
  export const FETCH_COUNTANOMALIESPERDATE_FAILURE = "FETCH_COUNTANOMALIESPERDATE_FAILURE";
  
  type FetchCountAnomaliesPerDateRequestAction = {
      type: typeof FETCH_COUNTANOMALIESPERDATE_REQUEST;
    };
    
    type FetchCountAnomaliesPerDateSuccessAction = {
      type: typeof FETCH_COUNTANOMALIESPERDATE_SUCCESS;
      payload: AnomaliesPerDate[];
    };
    type FetchCountAnomaliesPerDateFailureAction = {
      type: typeof FETCH_COUNTANOMALIESPERDATE_FAILURE;
      payload: string;
    };
  

//3일별근무시간외 로그인
export const FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_REQUEST = "FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_REQUEST";
export const FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_SUCCESS = "FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_SUCCESS";
export const FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_FAILURE = "FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_FAILURE";

type FetchCountLoginsPerDateOutOfWorkHoursRequestAction = {
    type: typeof FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_REQUEST;
  };
  
  type FetchCountLoginsPerDateOutOfWorkHoursSuccessAction = {
    type: typeof FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_SUCCESS;
    payload: LoginsPerDateOutOfWorkHours[];
  };
  type FetchCountLoginsPerDateOutOfWorkHoursFailureAction = {
    type: typeof FETCH_COUNTLOGINSPERDATEOUTOFWORKHOURS_FAILURE;
    payload: string;
  };


//4주말로그인현황
export const FETCH_COUNTWEEKENDLOGINSPERDATE_REQUEST = "FETCH_COUNTWEEKENDLOGINSPERDATE_REQUEST";
export const FETCH_COUNTWEEKENDLOGINSPERDATE_SUCCESS = "FETCH_COUNTWEEKENDLOGINSPERDATE_SUCCESS";
export const FETCH_COUNTWEEKENDLOGINSPERDATE_FAILURE = "FETCH_COUNTWEEKENDLOGINSPERDATE_FAILURE";

type FetchCountWeekendLoginsPerDateRequestAction = {
    type: typeof FETCH_COUNTWEEKENDLOGINSPERDATE_REQUEST;
  };
  
  type FetchCountWeekendLoginsPerDateSuccessAction = {
    type: typeof FETCH_COUNTWEEKENDLOGINSPERDATE_SUCCESS;
    payload: WeekendLoginsPerDate[];
  };
  type FetchCountWeekendLoginsPerDateFailureAction = {
    type: typeof FETCH_COUNTWEEKENDLOGINSPERDATE_FAILURE;
    payload: string;
  };

  //5 주말 근무시간 내 접속자 수
  export const FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_REQUEST = "FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_REQUEST";
  export const FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_SUCCESS = "FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_SUCCESS";
  export const FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_FAILURE = "FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_FAILURE";
  
  type FetchCountWeekendLoginsDuringWorkHoursRequestAction = {
      type: typeof FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_REQUEST;
    };
    
    type FetchCountWeekendLoginsDuringWorkHoursSuccessAction = {
      type: typeof FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_SUCCESS;
      payload: WeekendLoginsDuringWorkHours[];
    };
    type FetchCountWeekendLoginsDuringWorkHoursFailureAction = {
      type: typeof FETCH_COUNTWEEKENDLOGINSDURINGWORKHOURS_FAILURE;
      payload: string;
    };
  

    //6. 주말 근무시간 외 접속자 수 
    export const FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_REQUEST = "FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_REQUEST";
    export const FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_SUCCESS = "FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_SUCCESS";
    export const FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_FAILURE = "FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_FAILURE";
    
    type FetchCountWeekendLoginsOutOfWorkHoursRequestAction = {
        type: typeof FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_REQUEST;
      };
      
      type FetchCountWeekendLoginsOutOfWorkHoursSuccessAction = {
        type: typeof FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_SUCCESS;
        payload: WeekendLoginsOutOfWorkHours[];
      };
      type FetchCountWeekendLoginsOutOfWorkHoursFailureAction = {
        type: typeof FETCH_COUNTWEEKENDLOGINSOUTOFWORKHOURS_FAILURE;
        payload: string;
      };
    
      
export type DashBoardActionTypes =
  | FetchCountLoginsPerDateRequestAction
  | FetchCountLoginsPerDateSuccessAction
  | FetchCountLoginsPerDateFailureAction

  | FetchCountAnomaliesPerDateRequestAction
  |FetchCountAnomaliesPerDateSuccessAction
  |FetchCountAnomaliesPerDateFailureAction
  
  |FetchCountLoginsPerDateOutOfWorkHoursSuccessAction
  |FetchCountLoginsPerDateOutOfWorkHoursRequestAction
  |FetchCountLoginsPerDateOutOfWorkHoursFailureAction

  |FetchCountLoginsPerDateOutOfWorkHoursRequestAction
  |FetchCountLoginsPerDateOutOfWorkHoursSuccessAction
  |FetchCountLoginsPerDateOutOfWorkHoursFailureAction
  
  |FetchCountWeekendLoginsPerDateRequestAction
  |FetchCountWeekendLoginsPerDateSuccessAction
  |FetchCountWeekendLoginsPerDateFailureAction
  
  |FetchCountWeekendLoginsDuringWorkHoursRequestAction
  |FetchCountWeekendLoginsDuringWorkHoursSuccessAction
  |FetchCountWeekendLoginsDuringWorkHoursFailureAction

  |FetchCountWeekendLoginsOutOfWorkHoursRequestAction
  |FetchCountWeekendLoginsOutOfWorkHoursSuccessAction
  |FetchCountWeekendLoginsOutOfWorkHoursFailureAction;
