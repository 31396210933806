
import {
  FETCH_PAGED_PAYMENTBOARDLIST_SUCCESS,
  FETCH_PAYMENTBOARDLIST_FAILURE,
  FETCH_PAYMENTBOARDLIST_REQUEST,
  FETCH_PAYMENTBOARDLIST_SUCCESS,
  FETCH_PAYMENTBOARD_FAILURE,
  FETCH_PAYMENTBOARD_REQUEST,
  FETCH_PAYMENTBOARD_SUCCESS,
  PaymentBoardActionTypes,
  PaymentBoardState,
} from "../../types/paymentBoardTypes";

const initialState: PaymentBoardState = {
  loading: false,
  paymentBoard: null,
  paymentBoardList: [],
  pagedArticles: null,
  error: null,
};

// Reducer
export const paymentBoardReducer = (
  state = initialState,
  action: PaymentBoardActionTypes
): PaymentBoardState => {
  switch (action.type) {
    case FETCH_PAYMENTBOARDLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PAYMENTBOARDLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentBoardList: action.payload,
        error: "",
      };
      case FETCH_PAGED_PAYMENTBOARDLIST_SUCCESS:
        return {
          ...state,
          loading: false,
          pagedArticles: action.payload,
          error: "",
        };
    case FETCH_PAYMENTBOARDLIST_FAILURE:
      return {
        ...state,
        loading: false,
        paymentBoardList: [],
        error: action.payload,
      };

    case FETCH_PAYMENTBOARD_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_PAYMENTBOARD_SUCCESS:
      return { ...state, loading: false, paymentBoard: action.payload };
    case FETCH_PAYMENTBOARD_FAILURE:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
};
