import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Typography } from "@mui/material";
import mainRequest from "../../api/mainRequest";
import { AppDispatch, RootState } from "@/index";
import { useDispatch } from "react-redux";
import { fetchMasterKey, updateMasterKey } from "../../redux/manager/managerActions";
import { useSelector } from "react-redux";

interface UpdateMasterKeyForm {
  newMasterKey: string;
  // newMasterKeyConfirm: string;
}

const UpdateMasterKeyPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const masterKey = useSelector((state: RootState) => state.manager.masterKey);  
  const [formData, setFormData] = useState<UpdateMasterKeyForm>({
    newMasterKey: "",
    // newMasterKeyConfirm: "",
  });

  useEffect(() => {
    dispatch(fetchMasterKey());  // 컴포넌트 마운트 시 마스터 키 요청
  }, [dispatch]);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // 두 값이 같지 않으면 알림 표시 후 함수 종료
    // if (formData.newMasterKey !== formData.newMasterKeyConfirm) {
    //   alert("입력한 키가 일치하지 않습니다. 다시 입력해주세요.");
    //   return;
    // }
    dispatch(updateMasterKey({ newMasterKey: formData.newMasterKey }))
    .then(() => {
      setFormData({ newMasterKey: "" });
      
    })
    .catch(error => {
      // Handle any errors here
      console.error("업데이트 실패:", error);
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        마스터 키 수정
      </Typography>
      <form onSubmit={handleSubmit}>
      <TextField
          label="현재 마스터 키"
          variant="outlined"
          name="currentMasterKey"
          value={masterKey || "Loading..."}  // 마스터 키 표시, 로딩 중 표시
          InputProps={{
            readOnly: true,  // 마스터 키는 읽기 전용
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          label="새 마스터 키를 입력하세요"
          variant="outlined"
          name="newMasterKey"
          // type="password"
          value={formData.newMasterKey}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        {/* <TextField
          label="새 마스터 키를 다시 한번 입력하세요"
          variant="outlined"
          name="newMasterKeyConfirm"
          type="password"
          value={formData.newMasterKeyConfirm}
          onChange={handleChange}
          fullWidth
          margin="normal"
        /> */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 3 }}
        >
          변경
        </Button>
      </form>
    </Container>
  );
};

export default UpdateMasterKeyPage;
