// UserInfoPage.tsx
import { UserAllLoginDetailData, PagedResponse } from "../../types/userTypes";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import React from "react";
import PaginationByServer from "../PagenationByServer";
import {
  convertAllLogsToCSV,
  downloadCSV,
  formatDateTime,
  getFormattedDate,
  getHighlightStyle,
  isOutsideWorkingHours,
  isWeekend,
} from "./userUtils";
import { useNavigate } from "react-router-dom";
interface UserLogsFormProps {
  pagedLogs: PagedResponse<UserAllLoginDetailData> | null;
  handlePageChange: (pageNumber: number) => void; // 페이지 번호 변경 함수
  handleResetList: () => void; // 사원 목록 리셋 함수
}
const UserLogsForm: React.FC<UserLogsFormProps> = ({
  pagedLogs,
  handlePageChange,
  handleResetList,
}) => {
  const navigate = useNavigate();
  console.log("userLogsForm페이지 렌더링");

  const handleDownloadCSV = () => {
    if (pagedLogs && Array.isArray(pagedLogs.content)) {
      // 로컬 시간으로 날짜와 시간 설정
      const now = new Date();

      // 날짜 및 시간을 'YYYYMMDD-HHMMSS' 형식으로 포맷
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // 월은 0부터 시작하므로 1을 더함
      const day = now.getDate();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      const formattedDate = `${year}${month.toString().padStart(2, "0")}${day
        .toString()
        .padStart(2, "0")}-${hours.toString().padStart(2, "0")}${minutes
        .toString()
        .padStart(2, "0")}${seconds.toString().padStart(2, "0")}`;

      const currentPage = pagedLogs.currentPage || 1;
      const filename = `user-logs-${formattedDate}-page${currentPage}.csv`;
      const csvString = convertAllLogsToCSV(pagedLogs.content);
      downloadCSV(csvString, filename);
    }
  };

  if (!pagedLogs) {
    return <div>데이터가 없습니다.</div>;
  }
  if (!Array.isArray(pagedLogs.content)) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ width: "100%", minWith: "200px" }}>
        <TableContainer component={Paper} sx={{ marginBottom: 5 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Company
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  User Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Login
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Login Type
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  IP address
                </TableCell>
                {/* <TableCell
                  align="center"
                  sx={{
                    fontFamily: "'Nanum Gothic'",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Status
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagedLogs.content.map((user: UserAllLoginDetailData, index) => {
                const formattedDateTime = formatDateTime(user.loginDateTime);
                const isWeekendLogin = isWeekend(user.loginDateTime);
                const isOutsideHours = isOutsideWorkingHours(user.loginDateTime);
                const status = isWeekendLogin
                  ? isOutsideHours
                    ? "주말 시간외"
                    : "주말"
                  : isOutsideHours
                  ? "시간외"
                  : "정상";
                const highlightStyle = getHighlightStyle(
                  isWeekendLogin,
                  isOutsideHours
                );

                return (
                  <TableRow
                    key={`${user.userId}-${user.loginDateTime}-${index}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#f5f5f5", // You can change this color to any you like
                      },
                    }}
                    onClick={() =>
                      navigate(`/user-detail/${user.userId}?page=1`)
                    }
                  >
                    <TableCell align="center" sx={highlightStyle}>
                      {user.userId}
                    </TableCell>
                    <TableCell align="center" sx={highlightStyle}>
                      {user.companyName}
                    </TableCell>
                    <TableCell align="center" sx={highlightStyle}>
                      {user.ein}
                    </TableCell>
                    <TableCell align="center" sx={highlightStyle}>
                      {formattedDateTime}
                    </TableCell>
                    <TableCell align="center" sx={highlightStyle}>
                      {user.loginType}
                    </TableCell>
                    <TableCell align="center" sx={highlightStyle}>
                      {user.pcIp}
                    </TableCell>
                    {/* <TableCell align="center" sx={highlightStyle}>
                      {status}
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          // justifyContent="flex-end"
          // flexWrap="wrap"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box />

          {pagedLogs ? (
            <PaginationByServer
              pagedUnits={pagedLogs}
              handlePageChange={handlePageChange}
            />
          ) : (
            ""
          )}
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              onClick={handleDownloadCSV}
              sx={{ mt: 2, mb: 2 }}
            >
              CSV
            </Button>
            <Button
              variant="contained"
              onClick={handleResetList}
              sx={{ mt: 2, mb: 2 }}
            >
              로그 목록
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserLogsForm;
