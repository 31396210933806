import React from "react";
import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  ButtonGroup,
  IconButton,
  SelectChangeEvent,
  InputAdornment,
  Stack,
} from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import SearchIcon from "@mui/icons-material/Search";


interface Company {
  companyName: string;
}

interface UserFilterComponentProps {
  viewMode: "table" | "card";
  setViewMode: (mode: "table" | "card") => void;
  selectedCompany: string;
  setSelectedCompany: (company: string) => void;
  searchText: string;
  setSearchText: (text: string) => void;
  handleSearch: (event: React.KeyboardEvent | React.MouseEvent) => void;
  handleCompanyChange: (Event: SelectChangeEvent) => void;
  companyList: Company[];
}

const UserFilterComponent: React.FC<UserFilterComponentProps> = ({
  viewMode,
  setViewMode,
  selectedCompany,
  setSelectedCompany,
  searchText,
  setSearchText,
  handleSearch,
  handleCompanyChange,
  companyList,
}) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {/* 뷰 모드 토글 아이콘 */}
      <ButtonGroup variant="outlined" aria-label="view mode toggle">
        <IconButton
          onClick={() => setViewMode("table")}
          color={viewMode === "table" ? "primary" : "default"}
        >
          <TableRowsIcon />
        </IconButton>
        <IconButton
          onClick={() => setViewMode("card")}
          color={viewMode === "card" ? "primary" : "default"}
        >
          <ViewModuleIcon />
        </IconButton>
      </ButtonGroup>

      {/* 회사 선택 드롭다운 */}
      {companyList.length > 0 && (
        <FormControl
          variant="outlined"
          sx={{ marginLeft: 5, marginRight: 5, width: 200, minWidth: 100 }}
        >
          <InputLabel id="company-label">Company</InputLabel>
          <Select
            labelId="company-label"
            value={selectedCompany}
            onChange={handleCompanyChange}
            label="회사"
          >
            <MenuItem value="">
              <em>전체보기</em>
            </MenuItem>
            {companyList.map((company) => (
              <MenuItem value={company.companyName} key={company.companyName}>
                {company.companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* 검색창 */}
      <TextField
        label="Search user ID or name "
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && handleSearch(e)}
        sx={{ minWidth: 250 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default UserFilterComponent;
