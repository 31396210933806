import NoticeBoardRegisterForm from "../../components/noticeBoard/noticeBoardRegisterForm";
import React from "react";

const NoticeBoardRegisterPage: React.FC = () => {
  console.log(" NoticeBoardRegisterPage 렌더링")
  return (
    <>
    
      <div className="container mt-3 p-5">
      <h2>공지사항 등록</h2>
        <NoticeBoardRegisterForm/>
      </div>
    </>
  );
};

export default NoticeBoardRegisterPage;
