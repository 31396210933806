import TechnicalBoardDetailPage from "../pages/technicalBoard/technicalBoardListDetailPage";
import TechnicalBoardListPage from "../pages/technicalBoard/technicalBoardListPage";
import TechnicalBoardRegisterPage from "../pages/technicalBoard/technicalBoardRegisterPage";

const technicalBoardRoutes = [

  {
    path: "/technical-board-register",
    component: TechnicalBoardRegisterPage,
    allowedRoles: ["0", "1"],
  },
  {
    path: "/technical-board-list",
    component: TechnicalBoardListPage,
    allowedRoles: ["1"],
  },
  {
    path: "/technical-board-detail/:id",
    component: TechnicalBoardDetailPage,
    allowedRoles: ["1"],
  },



];

export default technicalBoardRoutes;
