import React, { useState } from "react";
import { TextField, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import mainRequest from "../../api/mainRequest";

interface UpdatePasswordForm {
  managerId: string;
  userId: string;
  newPassword: string;
}

const UpdatePwPage: React.FC = () => {
  const [formData, setFormData] = useState<UpdatePasswordForm>({
    managerId: "",
    userId: "",
    newPassword: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    try {
      const response = await mainRequest.put("/manager/onlyadmin/update-password", formData,config);
      console.log("Password update response:", response);
      alert("Password updated successfully!");
    } catch (error) {
      console.error("Failed to update password:", error);
      alert("Failed to update password.");
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Update Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Manager ID"
          variant="outlined"
          name="managerId"
          value={formData.managerId}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="User ID"
          variant="outlined"
          name="userId"
          value={formData.userId}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="New Password"
          variant="outlined"
          name="newPassword"
          type="password"
          value={formData.newPassword}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update Password
        </Button>
      </form>
    </Container>
  );
};

export default UpdatePwPage;
