import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
} from "@mui/material";
import {
  PagedResponse,
  NoticeBoardList,
} from "../../types/noticeBoardTypes";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "@/index";
import { useDispatch } from "react-redux";
import PaginationByServer from "../PagenationByServer";
import queryString from "query-string";
import { fetchPagedNoticeBoardList } from "../../redux/noticeBoard/noticeBoardActions";
interface NoticeBoardListFormProps {
  pagedArticles: PagedResponse<NoticeBoardList> | null;
}
interface TableCellWithOverflowProps {
  children: React.ReactNode;
  widthProp: string;
  isHeader?: boolean;
}

const TableCellWithOverflow: React.FC<TableCellWithOverflowProps> = ({
  children,
  widthProp,
  isHeader = false, // 기본값은 false
}) => (
  <TableCell
    sx={{
      maxWidth: "650px",
      width: widthProp, // prop으로 받은 widthProp로 너비 고정
      // widthProp: widthProp, // prop으로 받은 widthProp 사용
      // textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      // border: "1px solid lightgrey",
      // border:0,
      textAlign: "center",
      fontFamily: isHeader ? "'Nanum Gothic'" : undefined, // isHeader가 true일 때만 글꼴 적용
      fontSize: isHeader ? "16px" : undefined, // isHeader가 true일 때만 글자 크기 적용
      fontWeight: isHeader ? "bold" : undefined, // isHeader가 true일 때만 글자 굵기 적용
    }}
  >
    {children}
  </TableCell>
);

const NoticeBoardListForm: React.FC<NoticeBoardListFormProps> = ({
  pagedArticles,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const [searchText, setSearchText] = useState("");
  console.log("넘겨받은 pagedArticles", pagedArticles);
  console.log("넘겨받은 pagedArticles.content", pagedArticles?.content);

  // 화면 크기에 따라 항목 수 결정
  const determineItemsPerPage = () => {
    const height = window.innerHeight;
    return height < 1200 ? 12 : 24;
  };
  const [itemsPerPage, setItemsPerPage] = useState(determineItemsPerPage());

  useEffect(() => {
    const handleResize = () => {
      console.log("handle Resize 함수 작동");
      const newItemsPerPage = determineItemsPerPage();
      if (newItemsPerPage !== itemsPerPage) {
        setItemsPerPage(newItemsPerPage);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [itemsPerPage]);

  useEffect(() => {
    console.log("noticeBoardListForm-URL설정 useEffect");
    const values = queryString.parse(location.search);
    setSearchText((values.search as string) || "");
    const page = parseInt((values.page as string) || "1", 10);
    console.log("page:", page);
    console.log("size:", itemsPerPage);
    dispatch(
      fetchPagedNoticeBoardList({
        page: page,
        size: itemsPerPage,
        searchText: values.search as string,
      })
    );
  }, [dispatch, location.search, itemsPerPage]);

  const handleSearch = (event: React.KeyboardEvent | React.MouseEvent) => {
    event.preventDefault();
    navigate(
      `/notice-board-list?page=1&search=${encodeURIComponent(searchText)}`
    );
    dispatch(
      fetchPagedNoticeBoardList({
        size: itemsPerPage,
        searchText: searchText,
      })
    );
  };

  const handlePageChange = (pageNumber: number) => {
    const queryString = `?page=${pageNumber}&search=${encodeURIComponent(
      searchText
    )}`;
    navigate(`/notice-board-list${queryString}`);
    dispatch(
      fetchPagedNoticeBoardList({
        page: pageNumber,
        size: itemsPerPage,
        searchText: searchText,
      })
    );
  };

  console.log("noticeBoardListForm렌더링");

  //로우 클릭
  const handleRowClick = (noticeBoardId: string) => {
    navigate(`/notice-board-detail/${noticeBoardId}`); // 상세 페이지로 이동
  };

  return (
    <>
      <Box
        display="flex"
        // justifyContent="flex-end"
        // flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        // p={0}
        // mx={0}
        // my={0}
        sx={{}}
      >
        <Box>
          <span
            className="h3"
            style={{
              fontWeight: "bold",
              fontFamily: "'Nanum Gothic'",
              color: "black",
              // fontSize: "17px",
            }}
          >
            공지 사항
          </span>
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            label="Search contents or name"
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSearch(e)}
            sx={{ minWidth: 200, width: 350 }}
          />
          <Button
            variant="outlined"
            onClick={(e) => handleSearch(e)}
            sx={{ minWidth: "fit-content", height: 55 }}
          >
            <SearchIcon />
          </Button>
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          marginBottom: "30px",
          marginTop: "30px",
          // textOverflow: "ellipsis",
          // overflow: "hidden",
          // whiteSpace: "nowrap",
        }}
      >
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead sx={{ backgroundColor: "grey.200" }}>
            <TableRow>
              <TableCellWithOverflow widthProp="15%" isHeader>
                글번호
              </TableCellWithOverflow>
              <TableCellWithOverflow widthProp="55%" isHeader>
                제목
              </TableCellWithOverflow>
              <TableCellWithOverflow widthProp="15%" isHeader>
                작성자
              </TableCellWithOverflow>
              <TableCellWithOverflow widthProp="15%" isHeader>
                작성일
              </TableCellWithOverflow>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedArticles &&
            pagedArticles.content &&
            pagedArticles.content.length > 0 ? (
              pagedArticles.content.map(
                (noticeBoard: NoticeBoardList, index) => (
                  <TableRow
                    key={noticeBoard.noticeBoardId}
                    hover // 마우스 오버 스타일
                    onClick={() =>
                      handleRowClick(noticeBoard.noticeBoardId)
                    }
                    style={{ cursor: "pointer" }} // 마우스 포인터 변경
                  >
                    <TableCellWithOverflow widthProp="15%">
                      {noticeBoard.noticeBoardId}
                    </TableCellWithOverflow>
                    <TableCellWithOverflow widthProp="55%">
                      {noticeBoard.title}
                    </TableCellWithOverflow>
                    <TableCellWithOverflow widthProp="15%">
                      {noticeBoard.author}
                    </TableCellWithOverflow>
                    <TableCellWithOverflow widthProp="15%">
                      {noticeBoard.createdTime
                        ? new Date(noticeBoard.createdTime)
                            .toISOString()
                            .split("T")[0]
                        : ""}
                    </TableCellWithOverflow>
                  </TableRow>
                )
              )
            ) : (
              <TableRow>
                <TableCell colSpan={7} style={{ textAlign: "center" }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        // justifyContent="flex-end"
        // flexWrap="wrap"
        justifyContent="space-around"
        alignItems="center"
      >
        <Box />
        {pagedArticles ? (
          <PaginationByServer
            pagedUnits={pagedArticles}
            handlePageChange={handlePageChange}
          />
        ) : (
          ""
        )}
        <Button
          variant="contained"
          onClick={() => navigate("/notice-board-list")}
          sx={{ mt: 2, mb: 2 }} // 상단 및 하단 마진 적용
        >
          목록
        </Button>
      </Box>
    </>
  );
};

export default NoticeBoardListForm;
